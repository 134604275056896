<template>
    <v-card-title> {{ $filters.capitalize ( $t('user.code_vpn')) }}</v-card-title>
    <v-select
        v-model="selectedItem"
        :items="selectionItems"
        :label= "$filters.capitalize ( $t('user.download_qr'))" 
        @update:modelValue="getCode"
    ></v-select>
    <!--
    <v-btn @click='showCode'>
        {{ $filters.capitalize ( $t('user.display_qr')) }}
    </v-btn>
-->
   <!-- <v-btn @click='$store.dispatch("users/renewCode")'
        :disabled='$store.state.users.isLoading'>
        {{ $filters.capitalize ( $t('user.renew_qr'))}}
    </v-btn>
    -->
   <!--  <span>flip/button to show: TABLE with IP info </span>
    -->
</template>

<script>
import utils from "@/mixins/utils";

export default {
    name: "vipini",
	mixins: [utils],
    methods: {
        getCode(thing){
            let content_type = 'image/png'
            if (thing.includes('CONF')) content_type = 'text/plain'
            this.getFile("users/vpn/",thing, content_type, "blob", {'request_for':thing});
            },
//        showCode(){
//            let thing = this.$store.dispatch("users/vpn",)
//.then((thing)=>{console.log(thing)})
//            },
        },
    data() {
        return {
            selectedItem: undefined,
            selectionItems: ['Mobile QR', 'Mobile CONF', 'PC QR', 'PC CONF'],
        }
    },
};
</script>

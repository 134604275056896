<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="3" md="3">
        <v-text-field
          v-model="editedItem.description"
          :label="$filters.capitalize($t('external_repairs.description'))"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3" md="3">
        <v-autocomplete
          v-model="editedItem.assigned_to_id"
          :items="$store.state.users.search"
          item-title="name"
          item-value="id"
          :label="$filters.capitalize($t('external_repairs.assigned_to', 1) )"
          v-model:search="autocompleteSearchUser"
          :loading="$store.state.users.isSearching"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          cache-items
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-textarea
          v-model="editedItem.address"
          :label="$filters.capitalize($t('external_repairs.address'))"
          :rows="3"
          no-resize
          variant="solo"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          v-model="editedItem.tracking"
          :label="$filters.capitalize($t('external_repairs.tracking'))"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <DatePicker
          v-bind:label="'external_repairs.sent_at'"
          v-bind:value="editedItem.sent_at"
          @input="editedItem.sent_at = $event"
        >
        </DatePicker>
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <DatePicker
          v-bind:label="'external_repairs.quotation_at'"
          v-bind:value="editedItem.quotation_at"
          @input="editedItem.quotation_at = $event"
        >
        </DatePicker>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <DatePicker
          v-bind:label="'external_repairs.agreed_at'"
          v-bind:value="editedItem.agreed_at"
          @input="editedItem.agreed_at = $event"
        >
        </DatePicker>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <DatePicker
          v-bind:label="'external_repairs.returned_at'"
          v-bind:value="editedItem.returned_at"
          @input="editedItem.returned_at = $event"
        >
        </DatePicker>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-autocomplete
          v-model="editedItem.stockage_id"
          :items="$store.state.stockages.search"
          item-title="name"
          item-value="pk"
          :label="$filters.capitalize($t('stockages.label'))"
          v-model:search="autocompleteSearchStockage"
          :loading="$store.state.stockages.isSearching"
          :filter="filterStockages"
          prepend-icon="mdi-truck-delivery-outline"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("stockages.label", 1) })
                  )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="data">
<v-list-item @click="data.props.onClick" :title="data.item.raw.name+' -'+data.item.raw.city"/>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <DatePicker
          v-if="askReceivedAt"
          v-bind:label="'external_repairs.received_at'"
          v-bind:value="editedItem.received_at"
          @input="editedItem.received_at = $event"
        >
        </DatePicker>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { isNone } from "@/functions.js";
import filters from "@/mixins/filters";
import DatePicker from "@/components/DatePicker";

export default {
  components: {
    DatePicker: DatePicker,
  },

  mixins: [filters],

  props: {
    askReceivedAt: { type: Boolean, required: false, default: true },
  },

  computed: mapState({
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  }),

  mounted() {
    if (!isNone(this.editedItem.assigned_to_id)) {
      this.$store.dispatch("users/initSearch", {
        item_ids: this.editedItem.assigned_to_id,
      });
    } else {
      this.$store.dispatch("users/setSearch", this.$store.state.user);
      this.editedItem.assigned_to_id = this.$store.state.user.id;
    }
    if (!isNone(this.editedItem.stockage_id)) {
      this.$store.dispatch("stockages/initSearch", {
        item_ids: this.editedItem.stockage_id,
      });
    }
  },

  watch: {
    autocompleteSearchUser(val) {
      if (!this.$store.getters.canSearch("users")) return;
      this.$store.dispatch("users/searchItems", { value: val });
    },
    autocompleteSearchStockage(val) {
      if (!this.$store.getters.canSearch("stockages")) return;
      this.$store.dispatch("stockages/searchItems", { value: val });
    },
    editedItem: {
      handler() {
        this.$emit("subFormUpdate", "er");
      },
// TODO: according to migration info one ought to avoid deep watchers like this
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      moduleName: "external_repairs",
      autocompleteSearchUser: null,
      autocompleteSearchStockage: null,
    };
  },
};
</script>

<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col v-show="askStockPart" cols="12" sm="8" md="5">
        <v-autocomplete
          v-model="editedItem.stock_part_id"
          :items="$store.state.stock_parts.search"
          item-title="name"
          item-value="pk"
          :label="$filters.capitalize($t('stock_parts.label', 1))"
          v-model:search.sync="autocompleteSearchStockPart"
          :loading="$store.state.stock_parts.isSearching"
          :filter="filterStockParts"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("stock_parts.label", 1) })
                  )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template #item="{ item, props: { onClick } }">
            <v-list >
              <v-list-item 
                 @click="onClick">
{{item.raw.name}} {{item.raw.ref}}
</v-list-item>
            </v-list>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col v-show="askPlace" cols="12" sm="8" md="5">
        <v-autocomplete
          v-model="editedItem.place_id"
          :items="placesList"
          :item-title="$filters.formatPlace"
          item-value="pk"
          :label="$filters.capitalize($t('places.label', 1))"
          :loading="$store.state.places.isSearching"
v-model:search.sync="autocompleteSearchPlace"          
:filter="filterPlaces"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
        >
          <template #no-data>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("places.label", 1) })
                  )
                }}
          </template>
          <template #item="{item, props:{onClick}}">
            <v-list-item :title="$filters.formatPlace(item.raw)" @click="onClick">
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="4" md="2">
        <v-text-field
          v-model.number="editedItem.quantity"
          type="number"
          min="0"
          :label="$filters.capitalize($t('consumables.quantity'))"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";unProxy
import { isNone, unProxy } from "@/functions.js";
import { isNumber } from "chart.js/helpers";

export default {
  methods: { isNumber },
  mixins: [filters],

  computed: {
    editedItem: {
      get: function() {
        return this.$store.getters.dataEdit("consumables");
      },
      set: function(obj) {
        this.$store.dispatch("consumables/updateEdit", obj);
      }
    },
    placesList: function() {
      return this.$store.state.places.search;
    }
  },

  props: {
    askStockPart: { type: Boolean, required: false, default: true },
    askPlace: { type: Boolean, required: false, default: true },
    stockageId: { type: Number, required: false, default: undefined },
    placeId: { type: Number, required: false, default: undefined }
  },
  mounted() {
    if (!isNone(this.placeId)) {
      this.editedItem.place_id = this.placeId;
      if ((this.$store.state.places.search).some(({ pk }) => pk === this.placeId)) return;
    //   this.$store.dispatch("places/initSearch", { item_ids: this.placeId });
    // } else if (!isNone(this.stockageId)) {
      this.$store.dispatch("places/searchItems", {
        stockage_id: this.stockageId,
        size:this.$store.state.places.pagination.size || 300,
      });
    }
  },

  watch: {
     autocompleteSearchPlace(val) {
     if(!val) return
     if (!this.$store.getters.canSearch("places")) return;
     var payload = { value: val };
     if (this.stockageId !== undefined) {
       payload["stockage_id"] = this.stockageId;
    }
     this.$store.dispatch("places/searchItems", payload);
     },
    autocompleteSearchStockPart(val) {
      if (!this.$store.getters.canSearch("stock_parts")) return;
      this.$store.dispatch("stock_parts/searchItems", { value: val });
    },
    placeId(val) {
      this.editedItem.place_id = val;
      this.$store.dispatch("places/initSearch", { item_ids: val });
    },
    stockageId(val) {
      this.$store.dispatch("places/searchItems", { stockage_id: val });
    }
  },

  data() {
    return {
      autocompleteSearchPlace: null,
      autocompleteSearchStockPart: null
    };
  }
};
</script>

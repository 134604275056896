<template>
  <v-dialog
    v-model="editorShown"
    content-class="v-dialog--custom"
    @click:outside="clickOutside"
  >
    <v-card>
      <v-card-title
        :class="'headline ' + titleColorClass"
        primary-title
      ></v-card-title>

      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-show="!isOnCallDutyTriggered || isFromStaff"
              v-model="eventIlk"
              :disabled="isLocked"
              :items="ilks"
              item-title="label"
              item-value="value"
              :label="$filters.capitalize($t('scheduler.ilk.label', 1) )"
              @change="onIlkChoice($event)"
              variant="solo"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="eventProducerId"
              v-show="showProducerSelect"
              :items="$store.state.producers.all"
              item-title="display_name"
              item-value="pk"
              :label="$filters.capitalize($t('producers.label', 1) )"
              :loading="$store.state.producers.isSearching"
              :filter="filterProducers"
              :clearable="true"
              append-icon="mdi-text-box-search"
              density="compact"
              hide-details
              return-object
              @update:modelValue="computeEventName($event)"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{
                      $t("search_for", { noun: $filters.capitalize($t('producers.label', 1) ) })
                    }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="eventName"
              :disabled="isLocked"
              :label="$filters.capitalize($t('scheduler.name', 1) )"
              class="pb-3 event-name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-radio-group v-model="isRemote" :disabled="isLocked" row>
              <v-radio
                :label="$filters.capitalize($t('scheduler.is_remote.remote', 1))"
                :value="true"
                @input="onRemoteChange(true)"
              ></v-radio>
              <v-radio
                :label="$filters.capitalize($t('scheduler.is_remote.site', 1))"
                :value="false"
                @input="onRemoteChange(false)"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-textarea
          v-model="eventDescription"
          :disabled="isLocked"
          :label="$filters.capitalize($t('scheduler.description', 1) )"
          :height="80"
          variant="solo"
          no-resize
          ></v-textarea>
          <v-row>
            <v-col>
              <DatePartPicker
                v-show="!isOnCallDutyTriggered"
                :value="startDate"
                :isLocked="isLocked"
                :label="'scheduler.start'"
                :isStart='true'
                @inputDateTimePart="handleDateInputStartDate"
              />
            </v-col>
            <v-col>
              <DatePartPicker
                v-show="!isOnCallDutyTriggered"
                :value="endDate"
                :isLocked="isLocked"
                :label="'scheduler.end'"
                :isStart="false"
                @inputDateTimePart="handleDateInputEndDate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <DateTimePicker
                v-show="isOnCallDutyTriggered"
                :value="startDate"
                :isLocked="isLocked"
                :label="'scheduler.start'"
                :isStart='true'
                @inputDateTimePart="handleDateInputStartDate"
              />
            </v-col>
            <v-col>
              <DateTimePicker
                v-show="isOnCallDutyTriggered"
                :value="endDate"
                :isLocked="isLocked"
                :label="'scheduler.end'" 
                :isStart="false"
                @inputDateTimePart="handleDateInputEndDate"
              />
            </v-col>
          </v-row>
        <v-checkbox
          v-show="!isOnCallDutyTriggered"
          v-model="isLocked"
          v-if="isFromStaff"
        >
          <template #label>
            <span v-if="isLocked">{{
              $filters.capitalize($t('scheduler.locked', 1) )
            }}</span>
            <span v-else>{{ $filters.capitalize($t('scheduler.locked_off', 1) )}}</span>

          </template>
        </v-checkbox>

        <v-container v-show="isLocked && !isFromStaff">
          <v-icon class="mr-2">mdi-pencil-lock</v-icon>
          {{ $filters.capitalize($t("scheduler.locked_event")) }}
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          text
          @click="cancelEditor"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          :disabled="isLocked && !isFromStaff"
          color="primary"
          text
          @click="saveHandler"
          >{{ $t("save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import DatePartPicker from '@/components/scheduler/DatePartPicker';
import DateTimePicker from '@/components/scheduler/DateTimePicker';
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import { capitalize, isNone } from "@/functions.js";

export default {
  mixins: [utils, filters],
  name: "event-editor",

  components: {
    DatePartPicker,
    DateTimePicker
  },

  props: {
    value: [Boolean, Object],
    eventRecord: Object,
    eventStore: Object,
    resourceId: [String, Number],
  },

  mounted() {
    this.eventIlk = this.ilks[0]["value"];
  },

  watch: {
    eventRecord(value) {
      this.eventName = this.eventRecord ? this.eventRecord.name : '';
      this.eventDescription = this.eventRecord ? this.eventRecord.description : '';
      this.eventIlk = this.eventRecord ? this.eventRecord.ilk : '';
      this.isRemote = this.eventRecord ? this.eventRecord.is_remote : '';
      this.eventProducerId = this.eventRecord ? this.eventRecord.producer_id : '';
    },
  },

  methods: {
    computeEventName(event){
      if(event == undefined) return;
      this.eventName = "" +  event.description + " " +this.eventName;
    },
    handleDateInputEndDate(event) {
      if (event instanceof Date) {
        this.endDate = event;
      } else if (event.target && event.target.value) {
        this.endDate = new Date(event.target.value);
      }
    },
    handleDateInputStartDate(event) {
      if (event instanceof Date) {
        this.startDate = event;
      } else if (event.target && event.target.value) {
        this.startDate = new Date(event.target.value);
      }
    },

    onRemoteChange(value) {
      this.isRemote = value;
    },

    cancelEditor() {
      this.canceled = false;
      this.editorShown = false;
      this.$emit("close", this.eventRecord, this.canceled);
    },

    saveHandler() {
      const start = moment(this.startDate);
      const end = moment(this.endDate);

      if (isNone(this.eventName) || isNone(this.eventName.trim())) {
        this.$store.dispatch(
          "snackbar/showError",
          this.$t("snack.no_name"),
        );
        return false;
      }
      if (isNone(this.eventIlk)) {
        this.$store.dispatch(
          "snackbar/showError",
          this.$t("snack.no_ilk"),
        );
        return false;
      }
      if (isNone(this.eventDescription) && !this.isOnCallDutyEvent) {
        this.$store.dispatch(
          "snackbar/showError",
          this.$t("snack.no_description"),
        );
        return false;
      }
      if (start >= end) {
        this.$store.dispatch(
          "snackbar/showError",
          this.$t("snack.start_after_end"),
        );
        return false;
      }
      if (this.eventIlk.startsWith("oncallduty_triggered")) {
        if (start.hour() < 8) {
          this.$store.dispatch(
            "snackbar/showError",
            this.$t("snack.too_early"),
          );
          return false;
        }
        if (isNone(this.isRemote)) {
          this.$store.dispatch(
            "snackbar/showError",
            this.$t("scheduler.snack.choose_remote"),
          );
          return false;
        }
        if (!this.isRemote && (end - start) / (1000 * 60 * 60) < 1) {
          this.$store.dispatch(
            "snackbar/showError",
            this.$t("snack.too_short"),
          );
          return false;
        }
      }
      if (start <= moment().startOf("day").subtract(15, "days").toDate()) {
        this.$store.dispatch(
          "snackbar/showError",
          this.$t("snack.start_too_old"),
        );
        if (!this.isFromStaff) {
          return false;
        }
      }
      this.eventRecord.isCreating = false;
      this.eventRecord.set({
        name: this.eventName.trim(),
        description: this.eventDescription,
        ilk: this.eventIlk,
        locked: this.isLocked,
        is_remote: this.isRemote,
        ...(this.eventProducerId && { producer_id: this.eventProducerId.pk }),
        resourceId: this.resourceId,
      });

      // calling this method updates also duration (resizes the event)
      this.eventRecord.setStartEndDate(start.toDate(), end.toDate());

      if (!this.eventRecord.eventStore) {
        // New record is needed as Vue adds watchers to the original.
        // See https://github.com/bryntum/support/issues/2754
        const data = { ...this.eventRecord.data, resourceId: this.resourceId };
        this.eventStore.add(data);
      }

      this.canceled = false;
      this.editorShown = false;
      this.$emit("close", this.eventRecord, this.canceled);
    },

    onIlkChoice(value) {
      this.isVacation = ["rest", "holiday"].includes(value);
      if (this.isVacation) {
        this.eventName = capitalize(this.$t(`scheduler.ilk.${value}`));
      }
      // Force isOnCallDutyTriggered recalculation to adapt form
      this.eventRecord.ilk = value;

      if (this.eventRecord.ilk != "oncallduty_triggered") {
        const start_ = moment(this.eventRecord.startDate)
          .startOf("day")
          .toDate();
        const end_ = moment(this.eventRecord.endDate).endOf("day").toDate();
        this.$store.dispatch("scheduler/setStart", start_);
        this.$store.dispatch("scheduler/setEnd", end_);
      }
    },

    clickOutside() {
      this.canceled = true;
    },
  },

  created() {
    //this.autocompleteSearchProducer();
    if (this.$store.state.producers.all.length <= 0 && !this.$store.state.producers.isLoading) this.$store.dispatch('producers/getAll')
  },

  computed: {
    ...utils.isFromStaff,
    isOnCallDutyTriggered() {
      return (
        !isNone(this.eventRecord) &&
        this.eventRecord.ilk.startsWith("oncallduty_triggered")
      );
    },
    isOnCallDutyEvent() {
      return !isNone(this.eventRecord) && this.eventRecord.ilk === "oncallduty";
    },
    isWindProfil() {
      return this.$store.getters.isWindProfil;
    },
    showProducerSelect() {
      return !this.isVacation;
     // return this.isWindProfil && !this.isVacation;
    },
    titleColorClass() {
      if (!isNone(this.eventRecord) && !isNone(this.eventIlk)) {
        return this.eventIlk;
      }
      return "grey lighten-1";
    },
    startDate: {
      get() {
        return this.$store.state.scheduler.start;
      },
      set(value) {
        this.$store.dispatch("scheduler/setStart", value);
      },
    },
    endDate: {
      get() {
        return this.$store.state.scheduler.end;
      },
      set(value) {
        this.$store.dispatch("scheduler/setEnd", value);
      },
    },

    ilks() {
      var data = [
        {
          value: "work",
          label: capitalize(this.$t("scheduler.ilk.work")),  
        },
        {
          value: "holiday",
          label: capitalize(this.$t("scheduler.ilk.holiday")),
        },
        {
          value: "rest",
          label: capitalize(this.$t("scheduler.ilk.rest")),
        },
        {
          value: "trip",
          label: capitalize(this.$t("scheduler.ilk.trip")),
        },
        {
          value: "int_trip",
          label: capitalize(this.$t("scheduler.ilk.int_trip")),
        },
      ];
      if (this.isOnCallDutyTriggered || this.isFromStaff) {
        data = data.concat([
          {
            value: "oncallduty",
            label: capitalize(this.$t("scheduler.ilk.oncallduty")),
          },
          {
            value: "oncallduty_triggered",
            label: capitalize(
              this.$t("scheduler.ilk.oncallduty_triggered"),
            ),
          },
          {
            value: "oncallduty_rest",
            label: capitalize(this.$t("scheduler.ilk.oncallduty_rest")),
          },
        ]);
      }
      return data;
    },

    editorShown: {
      get() {
        const eventRecord = this.eventRecord;

        // we're opening so initialize data
        if (true === this.value) {
          if (eventRecord) {
            if (isNone(this.eventName) || isNone(this.eventName.trim())) {
              Object.assign(this, { eventName: eventRecord.data.name });
            }
            if (isNone(this.eventDescription)) {
              Object.assign(this, {
                eventDescription: eventRecord.data.description,
              });
            }
            if (isNone(this.eventProducerId)) {
              Object.assign(this, {
                eventProducerId: eventRecord.data.producer_id,
              });
            }
            if (isNone(this.isRemote)) {
              Object.assign(this, { isRemote: eventRecord.data.is_remote });
            }
            Object.assign(this, {
              eventIlk: eventRecord.data.ilk,
              isLocked: eventRecord.data.locked,
              isVacation: ["rest", "holiday"].includes(eventRecord.data.ilk), // consistency
              once: true,
            });
          }
        } else if (false === this.value) {
          if (this.once) {
            Object.assign(this, {
              eventName: "",
              eventDescription: "",
              eventProducerId: null,
              once: false,
            });
            this.$emit("close", eventRecord, this.canceled);
          }
        }
        // return always Boolean
        return !!this.value;
      },
      // only runs on close
      set(value) {
        const { eventRecord, eventStore } = this;
        if (eventRecord.isCreating) {
          eventStore.remove(eventRecord);
          eventRecord.isCreating = false;
        }
        this.$emit("input", value);
      },
    },
  }, // eo computed

  data() {
    return {
      eventName: "",
      eventDescription: "",
      eventIlk: "",
      eventProducerId: null,
      isLocked: false,
      isRemote: null,
      isVacation: false,
      once: true,
      canceled: false,
    };
  },
};
</script>


<style lang="scss">
.work {
  background-color: #018e48; // $ilk_work
}
.holiday {
  background-color: #f5db14; // $ilk_holiday
}
.rest {
  background-color: #dcf65a; // $ilk_rest;
}
.trip {
  background-color:#4b939b;  // $ilk_trip;
}
.int_trip {
  background-color: #1c77c3; // $ilk_int_trip;
}
.oncallduty {
  background-color: #bd6fef; // $ilk_oncallduty;
}
.oncallduty_triggered {
  background-color: #f23cb9;  // $ilk_oncallduty_triggered;
}
.oncallduty_rest {
  background-color: #755ddb; // $ilk_oncallduty_rest;
}

:deep( .v-dialog--custom) {
  width: 80vw;
}
@media screen and (max-width: 600px) {
  :deep( .v-dialog--custom) {
    width: 100vw;
  }
}
/* Desktop */
@media screen and (min-width: 768px) {
  :deep( .v-dialog--custom) {
    width: 50vw;
  }
}
@media screen and (min-width: 1264px) {
  :deep( .v-dialog--custom) {
    width: 40vw;
  }
}
</style
>

// Generic crud binder
import { api } from "@/services/api";
import { isNone } from "@/functions.js";

export default {
  fetch(location, query) {
    const cancelSource = localStorage.getItem("cancelSource");
    var payload = { params: query };
    if (!isNone(cancelSource)) {
      payload["cancelToken"] = cancelSource.token;
    }
    return api.get(`${location}/`, payload).then((response) => {
      if (response === undefined) {
        throw "canceled";
      }
      return response.data;
    }).catch(()=>{});
  },

  post(location, payload) {
    return new Promise((resolve, reject) => {
      api.post(`${location}/`, payload)
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            reject(new Error("Failed to create item. Response is undefined."));
          }
        })
        .catch((error) => {
          // Handle specific error cases
          if (error.response) {
            if (error.response.status === 400) {
              reject(new Error("Bad request. Please check the payload."));
            } else {
              reject(new Error("An unexpected error occurred while creating the item."));
            }
          } else if (error.message === "canceled") {
            reject(new Error("Request canceled."));
          } else {
            reject(error);
          }
        });
    });
  },

  put(location, payload) {
    return api.put(`${location}/${payload.pk}/`, payload).then((response) => {
      if (response === undefined) {
        throw "canceled";
      }
      return response.data;
    }).catch(()=>{});
  },

  put_without_id(location, payload) {
    return api.put(`${location}/`, payload).then((response) => {
      if (response === undefined) {
        throw "canceled";
      }
      return response.data;
    }).catch(()=>{});
  },

  put_file(location, file, name) {
    api.defaults.timeout = 60000;
    let formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "Content-Disposition": `attachment; filename=${name}`,
        "Content-Type": "multipart/form-data",
      },
    };

    return api.put(`${location}/`, formData, config).then((response) => {
      if (response === undefined) {
        throw "canceled";
      }
      return response.data;
    }).catch(()=>{});
  },

  delete(location, msgId) {
    return api.delete(`${location}/${msgId}/`).then((response) => {
      return response.data;
    });
  },

  delete_without_id(location) {
    return api.delete(`${location}/`).then((response) => {
      return response.data;
    }).catch(()=>{});
  },

  patch(location, id_, payload) {
    return api.patch(`${location}/${id_}/`, payload).then((response) => {
      return response.data;
    }).catch(()=>{});
  },
};

import { createApp } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import HighchartsVue from "highcharts-vue";
// import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import moment from "moment";
import { extendMoment } from "moment-range";


// Vuetify
import "vuetify/styles";
import colors from "vuetify/lib/util/colors";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import {
  capitalize,
  formatAvailability,
  formatBool,
  formatCategory,
  formatCategoryEn,
  formatDat,
  formatDate,
  formatDateTime,
  formatFullDateTime,
  formatTime,
  formatFloat,
  formatOrigin,
  formatPercentage,
  formatPeriod,
  formatPlace,
  formatPrice,
  formatVariety,
  affectationKindFilter,
  criticalityFilter,
  reportStatusFilter,
  unityFilter,
  varietyFilter
} from "./functions";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

// Vue.config.productionTip = false;

// import Highcharts from 'highcharts'
// import HighchartsVue from 'highcharts-vue'
// import highchartsMore from 'highcharts/highcharts-more'
// highchartsMore(Highcharts)
// Vue.use(HighchartsVue, {Highcharts})

// import more from 'highcharts/highcharts-more';
// more(Highcharts)


// Vue.use(VueTelInputVuetify, {
//   vuetify,
// });


extendMoment(moment);


const vuetify = createVuetify({
  components: components,
  directives: directives,
  theme: {
    themes: {
      light: {
        primary: "#0093bf",
        secondary: "#6b5b4f",
        primary_light: "#b1e2ec",
        accent: colors.amber,
        success: colors.green,
        warning: colors.orange,
        error: colors.red
      }
    },
    options: { customProperties: true }
    // see https://vuetifyjs.com/en/features/theme/#custom-properties
  }
});

const globalOptions = {
  mode: 'auto',
};

const app = createApp(App, {
  // i18n,
  // Vue3TouchEvents,
  setup() {
    const { $t } = useI18n();
    return { $t };
  }
});

app.use(router, axios);

if (process.env.NODE_ENV == "production") {
  Sentry.init({
   app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(),],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled

    // Keep vue original errors in console
    logErrors: true
  });
}

app.component('VueDatePicker',VueDatePicker);
app.use(vuetify);
app.use(i18n);
app.use(store);
app.use(HighchartsVue);
app.use(VueTelInput, globalOptions);

app.config.globalProperties.$filters = {
  capitalize,
  formatAvailability,
  formatBool,
  formatCategory,
  formatCategoryEn,
  formatDat,
  formatDate,
  formatDateTime,
  formatFullDateTime,
  formatTime,
  formatFloat,
  formatOrigin,
  formatPercentage,
  formatPeriod,
  formatPlace,
  formatPrice,
  formatVariety,
  affectationKindFilter,
  criticalityFilter,
  reportStatusFilter,
  unityFilter,
  varietyFilter
};


app.mount("#app");

<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud
          v-bind:headers="headers"
          v-bind:moduleName="moduleName"
          v-bind:saveOverride="saveOverride"
          v-bind:actionAddConsumable="false"
          v-bind:actionEdit="true"
          v-bind:actionPartToTrash="false"
          v-bind:actionDelete="true"
          @editItem="editItemEvent"
          @addItem="addItemEvent"
        >
          <template v-slot:edit_content>
            <ConsumableForm></ConsumableForm>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import ConsumableForm from "@/components/ConsumableForm";
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";

export default {
  mixins: [filters, utils],

  components: {
    Crud: Crud,
    ConsumableForm: ConsumableForm,
  },

  methods: {
    saveOverride() {
      return new Promise((resolve) => {
        this.$store
          .dispatch(`consumables/addItem`, this.editedItem)
          .then(() => {
            resolve();
          })
          .catch(() => {
            const msg = this.$t("consumables.added_error");
            this.$store.dispatch("snackbar/showError", msg);
            return;
          });
      });
    },

    addItemEvent() {
      this.editedItem.place_id = null;
      this.editedItem.stock_part_id = null;
      this.$store.dispatch("stock_parts/searchItems", { value: "" });
      this.$store.dispatch("places/searchItems", { value: "" });
    },
    editItemEvent(item) {
      this.$store.dispatch("places/searchItems", {
        stockage_id: item.place.stockage_id,
      });
      // this.$store.dispatch("stock_parts/searchItems", {stock_part_id:item.stock_part_id})
      this.$store
        .dispatch("stock_parts/getItem", item.stock_part_id)
        .then((item) => {
          this.$store.dispatch("stock_parts/setSearch", item);
        });
    },
  },

  data() {
    return {
      moduleName: "consumables",
      headers: [
        {
          title: this.$t("stock_parts.label"),
          key: "stock_part.ref",
        },
        {
          title: this.$t("stock_parts.name"),
          key: "stock_part.name",
        },
        {
          title: this.$t("stock_parts.name_en"),
          key: "stock_part.name_en",
        },
        {
          title: this.$t("consumables.quantity"),
          key: "quantity",
        },
        {
          title: this.$t("units.label"),
          key: "stock_part.unity",
        },
        {
          title: this.$t("stockages.label"),
          key: "place.stockage.name",
        },
        {
          title: this.$t("places.cabinet"),
          key: "place.cabinet",
        },
        {
          title: this.$t("places.shelf"),
          key: "place.shelf",
        },
        {
          title: this.$t("generic.created_at"),
          key: "created_at",
        },
        {
          title: this.$t("generic.created_by"),
          key: "created_by.first_name",
        },
        {
          title: this.$t("generic.created_by"),
          key: "created_by.last_name",
        },
        {
          title: this.$t("purchase_view.label"),
          key: "purchase_id",
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
        },
      ],
    };
  },
};
</script>

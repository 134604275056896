<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        v-if="showNav"
        v-model="drawer"
        :color="modeColor"
        expand-on-hover
        rail
        permanent
      >
        <v-list density="compact" nav>
          <v-list-item
          density="compact"
            active-class="secondary"
            :to="homeItem.path == '#' ? '' : homeItem.path"
            link
            :prepend-icon="homeItem.icon"
            :title="$filters.capitalize($t(homeItem.title, 1))"
          >
          </v-list-item>

          <!-- ============ Production section ============ -->
          <v-list-group no-action>
            <template v-slot:activator="{ props }">
              <v-list-item
              density="compact"
                v-bind="props"
                :title="$filters.capitalize($t('menu.production'))"
                prepend-icon="mdi-lightning-bolt-circle"
              ></v-list-item>
            </template>

            <v-list-item
              v-for="item in productionItems"
              active-class="secondary"
              :key="item.title"
              :to="item.path == '#' ? '' : item.path"
              :prepend-icon="item.icon"
              :title="$filters.capitalize($t(item.title))"
              density="compact"
              link
            >
            </v-list-item>
          </v-list-group>

          <!-- ============ Main section ============ -->
          <v-list-item
            v-for="item in items"
            active-class="secondary"
            :key="item.title"
            :to="item.path == '#' ? '' : item.path"
            :prepend-icon="item.icon"
            :title="
              item.multiple
                ? $filters.capitalize($t(item.title, 10))
                : $filters.capitalize($t(item.title, 1))
            "
            density="compact"
            link
          >
          </v-list-item>

          <!-- ============ Wind section ============ -->
          <div v-if="isWindProfil">
            <v-list-group no-action>
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  :title="$filters.capitalize($t('menu.maintenance'))"
                  prepend-icon="mdi-wrench"
                  density="compact"
                >
                </v-list-item>
              </template>

              <v-list-item
                v-for="witem in winditem"
                active-class="secondary"
                :key="witem.title"
                :to="witem.path == '#' ? '' : witem.path"
                :prepend-icon="witem.icon"
                :title="$filters.capitalize($t(witem.title, 1))"
                density="compact"
                link
              >
              </v-list-item>
            </v-list-group>
          </div>
          <div v-if="isWindProfil">
            <v-list-group no-action>
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  prepend-icon="mdi-book-open-page-variant"
                  :title="$filters.capitalize($t('menu.documentation'))"
                  density="compact"
                >
                </v-list-item>
              </template>

              <v-list-item
                v-for="tem in docitem"
                active-class="secondary"
                :key="tem.title"
                :to="tem.path == '#' ? '' : tem.path"
                :prepend-icon="tem.icon"
                :title="$filters.capitalize($t(tem.title, 1))"
                density="compact"
                link
              >
              </v-list-item>
            </v-list-group>
          </div>
          <!-- ============ Admin section ============ -->
          <v-list-group>
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-cog"
                :title="$filters.capitalize($t('menu.admin'))"
                density="compact"
              >
              </v-list-item>
            </template>

            <v-list-item
              v-for="item in filteredAdminItems"
              active-class="secondary"
              :key="item.title"
              :to="item.path == '#' ? '' : item.path"
              :prepend-icon="item.icon"
              :title="$filters.capitalize($t(item.title))"
              density="compact"
              link
            >
            </v-list-item>
          </v-list-group>
        </v-list>

        <!-- ============ Admin section : buttons ============ -->
        <template v-slot:append>
          <div class="my-5" style="min-width: 90%">
            <v-btn
              block
              color="primary-darken-2"
              width="100%"
              class="mb-2"
              icon="mdi-help-circle-outline"
              @click.prevent="openHelp()"
            >
            </v-btn>

            <v-list density="compact" nav style="width: 100%">
              <v-list-item
                v-for="item in endItems"
                active-class="secondary"
                :key="item.title"
                :to="item.path == '#' ? '' : item.path"
                :prepend-icon="item.icon"
                :title="$filters.capitalize($t(item.title))"
                density="compact"
                link
              >
              </v-list-item>
            </v-list>
          </div>
        </template>
      </v-navigation-drawer>

      <v-main>
        <!-- fill-height doesn't work because https://stackoverflow.com/a/65578074. set the height on v-main as 100vh cause no appbar so no spill -->
        <v-container fluid style="min-height: 100vh">
          <router-view id="router_view"></router-view>
        </v-container>
      </v-main>
      <Snackbar></Snackbar>
    </v-layout>
  </v-card>
</template>

<script>
import Snackbar from "@/components/Snackbar";

export default {

  name: "Fujin",
  components: { Snackbar },

  computed: {
    isSolarProfil() {
      return this.$store.getters.isSolarProfil;
    },
    isWindProfil() {
      return this.$store.getters.isWindProfil;
    },
    filteredAdminItems: function () {
      return this.adminItems.filter(
        (x) =>
          this.$store.getters.isUserFromStaff ||
          x.path === "/places" ||
          x.path === "/part_categories" ||
          x.path === "/suppliers"
      );
    },
    modeColor() {
      switch (process.env.NODE_ENV) {
        case "production":
          return "primary";
        default:
          return "pink";
      }
    },
  },

  watch: {
    $route(to) {
      document.title = to.meta.title || "Fujin";
      if (typeof this.$route.meta.showNav === "boolean") {
        this.showNav = to.meta.showNav;
      } else {
        this.showNav = true;
      }
    },
  },

  created() {
    if (typeof this.$route.meta.showNav === "boolean") {
      this.showNav = this.$route.meta.showNav;
    } else this.navMod();
  },

  methods: {
    openHelp() {
      this.$router.push({ name: "help" });
    },
    navMod() {
      this.drawer = true;
      this.showNav = false;
    },
  },

  data() {
    return {
      drawer: true,
      showNav: true,
      rail: true,
      homeItem: { title: "menu.home", icon: "mdi-view-dashboard", path: "/" },
      items: [
        {
          title: "menu.scheduler",
          icon: "mdi-chart-timeline",
          path: "/scheduler",
        },
        {
          title: "part_view.label",
          icon: "mdi-barcode",
          path: "/part_view",
          multiple: true,
        },
        {
          title: "part_edition.menu",
          icon: "mdi-barcode-scan",
          path: "/part_edition",
        },
        {
          title: "intervention_reports.label",
          icon: "mdi-clipboard-file-outline",
          path: "/intervention_reports",
          multiple: true,
        },
        {
          title: "todos.label",
          icon: "mdi-clipboard-check-multiple",
          path: "/todo_view",
        },
        {
          title: "purchase_view.label",
          icon: "mdi-shopping",
          path: "/purchase_view",
          multiple: true,
        },
        {
          title: "preventive_maintenances.label",
          icon: "mdi-doctor",
          path: "/maintenance_view",
          multiple: true,
        },
      ],
      productionItems: [
        {
          title: "menu.solar_production",
          icon: "mdi-solar-panel",
          path: "/solar_production",
        },
        {
          title: "menu.wind_production",
          icon: "mdi-wind-turbine",
          path: "/wind_production",
        },
        {
          title: "menu.automaton",
          icon: "mdi-boom-gate-outline",
          path: "/automaton",
        },
        {
          title: "prod_availability.label",
          icon: "mdi-eye-outline",
          path: "/prod_availability",
        },
        {
          title: "prod_availability_months.label",
          icon: "mdi-eye-settings-outline",
          path: "/prod_availability_month",
        },
        { title: "menu.telemetry", icon: "mdi-chart-bar", path: "/telemetry" },
        // {
        //   title: "analytics.label",
        //   icon: "mdi-home-analytics",
        //   path: "/analytics",
        // },
        {
          title: "limitations.label",
          icon: "mdi-tortoise",
          path: "/limitations",
        },
        {
          title: "alarms.label",
          icon: "mdi-alert-decagram",
          path: "/alarm_view",
        },
      ],
      winditem: [
        {
          title: "menu.annualnorth",
          icon: "mdi-wrench",
          path: "/maintenance_nord",
        },
        {
          title: "menu.annualwest",
          icon: "mdi-wrench",
          path: "/maintenance_ouest",
        },
        {
          title: "menu.annualest",
          icon: "mdi-wrench",
          path: "/maintenance_est",
        },
      ],
      docitem: [
        {
          title: "menu.conduite",
          icon: "mdi-book-open-page-variant",
          path: "/conduite_france",
        },
        {
          title: "menu.procedures",
          icon: "mdi-book-open-page-variant",
          path: "/procedures",
        },
        {
          title: "menu.inspections",
          icon: "mdi-book-open-page-variant",
          path: "/procedures",
        },
        {
          title: "menu.travaux",
          icon: "mdi-book-open-page-variant",
          path: "/travaux",
        },
      ],
      adminItems: [
        { title: "stockages.label", icon: "mdi-city", path: "/stockages" },
        { title: "places.label", icon: "mdi-bookshelf", path: "/places" },
        {
          title: "power_stations.label",
          icon: "mdi-city-variant",
          path: "/power_stations",
        },
        {
          title: "producer_types.label",
          icon: "mdi-speaker-multiple",
          path: "/producer_types",
        },
        {
          title: "producers.label",
          icon: "mdi-guitar-electric",
          path: "/producers",
        },
        {
          title: "part_categories.label",
          icon: "mdi-cat",
          path: "/part_categories",
        },
        {
          title: "suppliers.label",
          icon: "mdi-package-variant-closed",
          path: "/suppliers",
        },
        {
          title: "stock_parts.label",
          icon: "mdi-tooltip-edit",
          path: "/stock_parts",
        },
        { title: "units.label", icon: "mdi-barcode", path: "/units" },
        {
          title: "consumables.label",
          icon: "mdi-barcode-off",
          path: "/consumables",
        },
        {
          title: "external_repairs.label",
          icon: "mdi-wrench",
          path: "/external_repairs",
        },
        {
          title: "internal_repairs.label",
          icon: "mdi-wrench",
          path: "/internal_repairs",
        },
        {
          title: "todos.label",
          icon: "mdi-clipboard-check-multiple",
          path: "/todos",
        },
        {
          title: "affectations.label",
          icon: "mdi-link-variant",
          path: "/affectations",
        },
        {
          title: "purchases.label",
          icon: "mdi-cart-outline",
          path: "/purchases",
        },
        {
          title: "purchase_lines.label",
          icon: "mdi-cart-plus",
          path: "/purchase_lines",
        },
        {
          title: "preventive_maintenances.label",
          icon: "mdi-doctor",
          path: "/preventive_maintenances",
        },
        {
          title: "alarm_codes.label",
          icon: "mdi-alarm-light",
          path: "/alarm_codes",
        },
        {
          title: "spot_price_contracts.label",
          icon: "mdi-draw-pen",
          path: "/spot_price_contracts",
        },
        {
          title: "PRSP",
          icon: "mdi-alarm-light",
          path: "/performance_ratio_solar_panel",
        },
      ],
      endItems: [
        { title: "menu.logout", icon: "mdi-logout", path: "/logout" },
        { title: "menu.user", icon: "mdi-account-circle", path: "/user" },
      ],
      background: false,
    };
  },
};
</script>

<style lang="scss">

.v-main {
  background-color: #f7f5f0;
  .container#router_view {
    padding: 4px;
  }
}

th {
  text-transform: capitalize;
}

td {
text-align: left;
}

// Fix dark theme and other things on list-group
.theme--dark.v-list div.v-list-group--active,
.v-list-group__items a.v-list-item--active {
  color: white !important;
}
.v-list-group__items > .v-list-item {
  padding-left: 1.5rem !important;
}

// Fix minimal btn width (upgrade vuetify)
.v-btn:not(.v-btn--round).v-size--default {
  min-width: auto;
}

.v-btn {
  border-radius: 4px !important;
}
</style>

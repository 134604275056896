<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <DatePicker
          v-bind:label="'affectations.date'"
          v-bind:value="editedItem.date"
          @input="updateDate"
        >
        </DatePicker>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="showSerial">
        <v-autocomplete
          v-model="editedItem.unit_id"
          :items="$store.state.units.search"
          item-title="serial"
          item-value="pk"
          :label="$filters.capitalize($t('units.label'))"
          v-model:search="autocompleteSearchUnit"
          :loading="$store.state.units.isSearching"
          :filter="filterParts"
          no-filter
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
        >
          <template v-slot:no-data>
            <v-list-item :title="$filters.capitalize( $t('search_for', { noun: $t('units.label', 1) }))"/>
          </template>
          <template v-slot:item="data">
            <v-list-item @click="data.props.onClick" 
                :title=" data.item.raw.stock_part.category.name+' - '+data.item.raw.serial"/>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="editedItem.kind"
          :items="affectionTypes"
          item-title="name"
          item-value="id"
          :label="$filters.capitalize($t('affectations.choose_type'))"
          :disabled="!askKind"
          variant="solo"
          @update:modelValue="selectKind"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col :v-show="showSubSelect">
        <v-card v-if="editedItem.kind == 'ir'" class="pa-4" elevation="6"
          ><InternalRepairForm
            @subFormUpdate="subFormUpdateEvent"
          ></InternalRepairForm
        ></v-card>
        <v-card v-if="editedItem.kind == 'er'" class="pa-4" elevation="6"
          ><ExternalRepairForm
            @subFormUpdate="subFormUpdateEvent"
          ></ExternalRepairForm
        ></v-card>
        <v-card v-if="editedItem.kind == 'p'" class="pa-4" elevation="6"
          ><ProducerForm
            @updateProducer="subFormUpdateEvent('p', id=$event)"
          ></ProducerForm
        ></v-card>
        <!-- TODO: to implement change of stockage-->
        <!-- <v-card v-if="editedItem.kind == 'ss'" class="pa-4" elevation="6"
          ><StockageForm  @subFormUpdate="subFormUpdateEvent"></StockageForm
        ></v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
// import { isNone } from "@/functions";
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import InternalRepairForm from "@/components/InternalRepairForm";
import ExternalRepairForm from "@/components/ExternalRepairForm";
import ProducerForm from "@/components/ProducerForm";
// import StockageForm from "@/components/StockageForm";
import DatePicker from "@/components/DatePicker";

export default {
  mixins: [filters, utils],

  components: {
    InternalRepairForm,
    ExternalRepairForm,
    ProducerForm,
    // StockageForm,
    DatePicker: DatePicker,
  },

  props: {
    askKind: { type: Boolean, required: false, default: true },
    withProducer: { type: Boolean, required: false, default: true },
    showSerial: { type: Boolean, required: false, default: true },
  },

  computed: {
    kindId: {
      get: function () {
        return this.$store.state.affectations.kindId;
      },
      set: function (value) {
        this.$store.state.affectations.kindId = value;
      },
    },
    affectionTypes: function () {
      var liste = [
        { name: this.$t("external_repairs.label", 1), id: "er" },
        { name: this.$t("internal_repairs.label", 1), id: "ir" },
        { name: this.$t("stock_state.label", 1), id: "ss" },
        { name: this.$t("trash_state.label", 1), id: "ts" },
      ];
      if (this.withProducer) {
        liste.push({ name: this.$t("producers.label", 1), id: "p" });
      }
      return liste;
    },
    ...mapState({
      editedItem: function () {
        return this.$store.getters.dataEdit("affectations");
      },
      kind: function () {
        return this.editedItem.kind;
      },
    }),
  },

  methods: {
    subFormUpdateEvent(type, id=undefined) {
      if (type == "ir") {
        this.editedItem.internal_repair =
          this.$store.state.internal_repairs.edit;
        this.editedItem.internal_repair_id = this.$store.state.internal_repairs
          .edit.pk
          ? this.$store.state.internal_repairs.edit.pk
          : 1;
      }
      if (type == "er") {
        this.editedItem.external_repair =
          this.$store.state.external_repairs.edit;
        this.editedItem.external_repair_id = this.$store.state.external_repairs
          .edit.pk
          ? this.$store.state.external_repairs.edit.pk
          : 1;
      }
      if (type == "p") {
        this.editedItem.producer_id = id;
      }
      // if(type=="ss"){
      // this.editedItem.stockage_id = this.$store.state.stockages.edit.pk
      // }
    },
    selectKind(item) {
      if (item in ["ir", "er", "p"]) this.showSubSelect = true;
      else this.showSubSelect = false;
      this.updateKindId();
    },

    updateKindId() {
      this.$store.dispatch("updateAffectationTarget", {
        type: this.editedItem.kind,
        id: this.kindId,
      });
      // TODO: target objects to be updated too
    },

    updateDate(value) {
      let d_ = moment(value).toDate();
      d_.setHours(10);
      d_.setMinutes(0);
      this.editedItem.date = moment(d_).format("YYYY-MM-DDThh:mm");
    },
  },

  watch: {
    kindId() {
      this.selectKind(this.editedItem.kind);
    },
    autocompleteSearchUnit(val) {
      if (!this.$store.getters.canSearch("units")) return;
      this.$store.dispatch("units/searchItems", { value: val });
    },
  },

  data() {
    return {
      moduleName: "affectations",
      showSubSelect: false,
      autocompleteSearchUnit: null,
    };
  },
};
</script>

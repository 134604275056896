import {createRouter, createWebHashHistory} from "vue-router";

import store from "./store";
import Login from "@/views/Login";
import Affectations from "@/views/Crud/Affectations";
import AlarmsView from "@/views/AlarmsView";
import AlarmCodes from "@/views/Crud/AlarmCodes";
import PerformanceRatioSolarPanel from "@/views/Crud/PerformanceRatioSolarPanel";
import Analytics from "@/views/AnalyticsView";
import Automatons from "@/views/AutomatonsView";
import Consumables from "@/views/Crud/Consumables";
import ExternalRepairs from "@/views/Crud/ExternalRepairs";
import Help from "@/views/Help";
import Home from "@/views/Home";
import InternalRepairs from "@/views/Crud/InternalRepairs";
import InterventionReportEdition from "@/views/InterventionReportEdition";
import InterventionReportView from "@/views/InterventionReportView";
import PreventiveMaintenances from "@/views/Crud/PreventiveMaintenances";
import LimitationView from "@/views/LimitationView";
import MaintenanceEdition from "@/views/MaintenanceEdition";
import MaintenanceView from "@/views/MaintenanceView";
import PartEdition from "@/views/PartEdition";
import PartView from "@/views/PartView";
import PartCategories from "@/views/Crud/PartCategories";
import Places from "@/views/Crud/Places";
import PowerStations from "@/views/Crud/PowerStations";
import ProdAvailability from "@/views/ProdAvailabilityView";
import ProdAvailabilityMonth from "@/views/ProdAvailabilityMonthView";
import Producers from "@/views/Crud/Producers";
import ProducerTypes from "@/views/Crud/ProducerTypes";
import Purchases from "@/views/Crud/Purchases";
import PurchaseLines from "@/views/Crud/PurchaseLines";
import PurchaseView from "@/views/PurchaseView";
import SolarProduction from "@/views/SolarProduction";
import SpotPriceContracts from "@/views/Crud/SpotPriceContracts";
import WindProduction from "@/views/WindProduction";
import Scheduler from "@/views/Scheduler";
import StockParts from "@/views/Crud/StockParts";
import Stockages from "@/views/Crud/Stockages";
import Suppliers from "@/views/Crud/Suppliers";
import Telemetry from "@/views/Telemetry";
import TelemetryBoard from "@/views/TelemetryBoard";
import TelemetryGeneric from "@/views/TelemetryGeneric";
import TelemetryNamibie from "@/views/TelemetryNamibie";
import Todos from "@/views/Crud/Todos";
import TodoView from "@/views/TodoView";
import Units from "@/views/Crud/Units";
import User from "@/views/User";
import UnitView from "@/views/UnitView";
import Logout from "@/views/Logout";
import TelemetryChartSolar from "./components/TelemetryChartSolar.vue";
import TelemetryChartSolarGrafana from "./components/TelemetryChartSolarGrafana.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      showNav: false
    }
  },
  {
    path: "",
    name: "home",
    component: Home,
    meta: {
      title: "Accueil",
      requiresAuth: true
    }
  },
  {
    path: "/solar_production",
    name: "solar_production",
    component: SolarProduction,
    meta: {
      title: "Production solaire",
      requiresAuth: true
    }
  },
  {
    path: "/wind_production",
    name: "wind_production",
    component: WindProduction,
    meta: {
      title: "Production éolienne",
      requiresAuth: true
    }
  },
  {
    path: "/scheduler/:year?/:week?",
    name: "scheduler",
    component: Scheduler,
    props: true,
    meta: {
      title: "Scheduler",
      requiresAuth: true
    }
  },
  {
    path: "/telemetry",
    name: "telemetry",
    component: Telemetry,
    meta: {
      title: "Telemetry",
      requiresAuth: true
    }
  },
  {
    path: "/telemetry_view",
    name: "telemetry_view",
    component: TelemetryBoard,
    meta: {
      title: "TelemetryBoard",
      requiresAuth: false,
      showNav: false
    }
  },
  {
    path: "/telemetry_generic",
    name: "telemetry_generic",
    component: TelemetryGeneric,
    meta: {
      title: "Telemetry",
      requiresAuth: false,
      showNav: false
    }
  },
  {
    path: "/telemetry_solar_grafana",
    name: "telemetry_solar_grafan",
    component: TelemetryChartSolarGrafana,
    meta: {
      title: "Telemetry Solar Grafana",
      requiresAuth: false,
      showNav: false
    }
  },
  {
    path: "/telemetry_solar",
    name: "telemetry_solar",
    component: TelemetryChartSolar,
    meta: {
      title: "Telemetry Solar",
      requiresAuth: false,
      showNav: false
    }
  },
  {
    path: "/telemetry_namibie",
    name: "telemetry_namibie",
    component: TelemetryNamibie,
    meta: {
      title: "Telemetry",
      requiresAuth: false,
      showNav: false
    }
  },
  {
    path: "/automaton",
    name: "automaton",
    component: Automatons,
    meta: {
      title: "Automaton",
      requiresAuth: true
    }
  },
  {
    path: "/part_view",
    name: "part_view",
    component: PartView,
    meta: {
      title: "PartView",
      requiresAuth: true
    }
  },
  {
    path: "/part_edition/:part?",
    name: "part_edition",
    component: PartEdition,
    props: true,
    meta: {
      title: "PartEdition",
      requiresAuth: true
    }
  },
  {
    path: "/intervention_reports",
    name: "intervention_reports",
    component: InterventionReportView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/intervention_reports/edition/:pk?",
    name: "intervention_reports_edition",
    component: InterventionReportEdition,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/limitations",
    name: "limitation_view",
    component: LimitationView,
    props: false,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/todo_view",
    name: "todo_view",
    component: TodoView,
    meta: {
      title: "TodoView",
      requiresAuth: true
    }
  },
  {
    path: "/purchase_view",
    name: "purchase_view",
    component: PurchaseView,
    meta: {
      title: "PurchaseView",
      requiresAuth: true
    }
  },
  {
    path: "/maintenance_view",
    name: "maintenance_view",
    component: MaintenanceView,
    meta: {
      title: "MaintenanceView",
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/alarm_view",
    name: "alarms",
    component: AlarmsView,
    meta: {
      title: "Alarms",
      requiresAuth: true
    }
  },
  {
    path: "/maintenance_edition",
    name: "maintenance_edition",
    component: MaintenanceEdition,
    meta: {
      title: "MaintenanceEdition",
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/analytics",
    name: "analytics",
    component: Analytics,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/part_categories",
    name: "part_categories",
    component: PartCategories,
    meta: {
      title: "PartCategories",
      requiresAuth: true
    }
  },
  {
    path: "/units",
    name: "units",
    component: Units,
    meta: {
      title: "Units",
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/consumables",
    name: "consumables",
    component: Consumables,
    meta: {
      title: "Consumables",
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/stock_parts",
    name: "stock_parts",
    component: StockParts,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/suppliers",
    name: "suppliers",
    component: Suppliers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/stockages",
    name: "stockages",
    component: Stockages,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/external_repairs",
    name: "external_repairs",
    component: ExternalRepairs,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/internal_repairs",
    name: "internal_repairs",
    component: InternalRepairs,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/affectations",
    name: "affectations",
    component: Affectations,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/prod_availability",
    name: "prod_availability",
    component: ProdAvailability,
    meta: {
      requiresAuth: false,
      requiresStaff: true
    }
  },
  {
    path: "/prod_availability_month",
    name: "prod_availability_month",
    component: ProdAvailabilityMonth,
    meta: {
      requiresAuth: false,
      requiresStaff: true
    }
  },
  {
    path: "/producers",
    name: "producers",
    component: Producers,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/producer_types",
    name: "producer_types",
    component: ProducerTypes,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/power_stations",
    name: "power_stations",
    component: PowerStations,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/places",
    name: "places",
    component: Places,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/purchases",
    name: "purchases",
    component: Purchases,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/purchase_lines",
    name: "purchase_lines",
    component: PurchaseLines,
    meta: {
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/todos",
    name: "todos",
    component: Todos,
    meta: {
      title: "Todos",
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/preventive_maintenances",
    name: "preventive_maintenances",
    component: PreventiveMaintenances,
    meta: {
      title: "PreventiveMaintenances",
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/alarm_codes",
    name: "alarm_codes",
    component: AlarmCodes,
    meta: {
      title: "AlarmCodes",
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/performance_ratio_solar_panel",
    name: "performance_ratio_solar_panel",
    component: PerformanceRatioSolarPanel,
    meta: {
      title: "PerformanceRatioSolarPanel",
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/spot_price_contracts",
    name: "spot_price_contracts",
    component: SpotPriceContracts,
    meta: {
      title: "SpotPriceContracts",
      requiresAuth: true,
      requiresStaff: true
    }
  },
  {
    path: "/help",
    name: "help",
    component: Help,
    meta: {
      title: "Help",
      requiresAuth: true
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout
  },
  {
    path: "/user",
    name: "user",
    component: User
  },
  {
    path: "/unit",
    name: "unit",
    component: UnitView,
    props: true,
    meta: {
      title: "UnitView",
      requiresAuth: true
    }
  },
  {
    path: "/maintenance_nord",
    name: "maintenance_nord",
    beforeEnter() {
      const url =
        "https://docs.google.com/spreadsheets/d/1M54vw2-brEyl3j02v8ETu1UAA54gtvAE/edit#gid=289611645";
      window.open(url, "_blank");
    }
  },
  {
    path: "/maintenance_ouest",
    name: "maintenance_ouest",
    beforeEnter() {
      const url =
        "https://docs.google.com/spreadsheets/d/1Yoxb9z3IjblbeAQHSRmLYlOnW7ZSELo6/edit#gid=1432303525";
      window.open(url, "_blank");
    }
  },
  {
    path: "/maintenance_est",
    name: "maintenance_est",
    beforeEnter() {
      const url =
        "https://docs.google.com/spreadsheets/d/1WLxmJDEZxfsCHCc9aaK3YKlNf1RepY1J/edit?usp=sharing&ouid=107273734536108000194&rtpof=true&sd=true";
      window.open(url, "_blank");
    }
  },
  {
    path: "/conduite_france",
    name: "conduite_france",
    beforeEnter() {
      const url =
        "https://docs.google.com/spreadsheets/d/1kH-qBsd6bicJSGY8dbnZlfydfy4YdREpLJV8rPfuWfw/edit?usp=sharing";
      window.open(url, "_blank");
    }
  },
  {
    path: "/procedures",
    name: "procedures",
    beforeEnter() {
      const url =
        "https://drive.google.com/drive/folders/18OyNqm-uqvdEp_ejGTEKkNVuuwtAurmJ";
      window.open(url, "_blank");
    },
  },
  {
    path: "/inspections",
    name: "inspections",
    beforeEnter() {
      const url =
        "https://drive.google.com/drive/folders/14O6yT1uy-utoCRe04l9-XAv_SUUHRqaF";
      window.open(url, "_blank");
    },
  },
  {
    path: "/travaux",
    name: "travaux",
    beforeEnter() {
      const url =
        "https://drive.google.com/drive/folders/1czBklIuUCjYdymMWfUaY_xxuW4QEuuOk";
      window.open(url, "_blank");
    },
  },
];

var router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (token && !store.getters.token) {
    store.commit("auth_update_token", token);
  }
  if (to.query && "auth_token" in to.query) {
    const auth_token = to.query.auth_token;
    store.commit("auth_update_auth_token", auth_token);
    next();
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      if (to.matched.some((record) => record.meta.requiresStaff)) {
        if (store.getters.isUserFromStaff) {
          next();
          return;
        } else {
          store.dispatch(
            "snackbar/showError",
            capitalize(this.$t("error.forbidden")),
          );
          return;
        }
      }
      next();
      return;
    }
    next({
      path: "/login",
      query: { next: to.path }, // the desired page
    });
  } else {
    next();
  }
});

export default router;

import crud from "./crud";
import crud_service from "@/services/crud";

import { isNone } from "@/functions";

const path = "consumables";
const struct = {
  quantity: "",
  place_id: null,
  stock_part_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,

  setEditPlace(state, place_id) {
    state.edit.place_id = place_id;
  },
  setEditStockPart(state, stock_part_id) {
    state.edit.stock_part_id = stock_part_id;
  },
};

const actions = {
  ...generic.actions,

  setEditPlace({ commit }, item) {
    commit("setEditPlace", item);
  },
  setEditStockPart({ commit }, stock_part_id) {
    commit("setEditStockPart", stock_part_id);
  },

  getItems({ commit }, options) {
    // Get only real items (sum > 0)
    var payload = {};
    if (options) {
      if (!isNone(options.page)) {
        payload["page"] = options.page;
      }
      if (!isNone(options.size)) {
        payload["size"] = options.size;
      }
      if (!isNone(options.ordering)) {
        payload["ordering"] = options.ordering;
      }

      if (!isNone(options.search)) {
        payload["search"] = options.search;
      }
    }
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/real`, payload)
        .then((items) => {
          commit("setItems", items.results);
          commit("setPagination", items);
          commit("setIsLoading", false);
          resolve();
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  searchRealItems({ dispatch }, options) {
    options["path_"] = `${path}/real`;
    return dispatch("searchItems", options);
  },

  addItem({ commit }, item) {
    // Create a new item
    return new Promise((resolve) => {
      crud_service.post("parts/full", item).then((response) => {
        commit("updateEdit", response);
        commit("addItem", response);
        resolve(response);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

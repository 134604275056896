import crud from "./crud";
import crud_service from "@/services/crud";

const path = "todos";
const struct = {
  description: "",
  fullfilled_at: null,
  user_id: null,
  producer_id: null,
  intervention_report_id: null,
  // is_risk is not part of model, only used for request handling
  is_risk: false,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  getUndone({ commit }, producer_id) {
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/undone`, { producer_id: producer_id })
        .then((items) => {
          commit("setItems", items);
          resolve(items);
        });
    });
  },

  markAsFullfilled({ commit }, { todo_id, fullfilled_at }) {
    return new Promise((resolve) => {
      crud_service
        .post(`todos/${todo_id}/fullfill`, { fullfilled_at: fullfilled_at })
        .then((item) => {
          commit("nothing");
          resolve(item);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

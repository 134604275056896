import crud from "./crud";
import crud_service from "@/services/crud";

const path = "places";
const struct = {
  cabinet: "",
  shelf: null,
  stockage_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  searchItemsFromProducer({ commit }, { producer_id, value }) {
    // search places of specific producer
    commit("setIsSearching", true);
    const payload = {
      producer_id: producer_id,
      search: value,
    };
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}`, payload)
        .then((items) => {
          commit("setSearch", items.results);
          commit("setIsSearching", false);
          resolve();
        })
        .catch(() => {
          commit("setIsSearching", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

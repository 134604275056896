<template v-slot:activator="{ props }">
  <v-container>
    <v-row>
      <v-col>
        <DatePicker
          :label="label"
          :value="dateValue"
          @input="handleDateChanges"
          class="mx-2"
        ></DatePicker>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          :items="hours"
          v-model="selectedHour"
          :label="$filters.capitalize($t('date_time_picker.select_hour'))"
          @update:modelValue="handleTimeChange"
          class="time-select"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          :items="minuteIntervals"
          v-model="selectedMinute"
          :label="$filters.capitalize($t('date_time_picker.select_minute'))"
          @update:modelValue="handleTimeChange"
          class="time-select"
        ></v-select>
      </v-col>
    </v-row>
    <v-text-field
      v-model="displayedDateTime"
      :label="$filters.capitalize($t('date_time_picker.time_selected'))"
      readonly
      class="time-display"
    ></v-text-field>
  </v-container>
</template>
<script>
import DatePicker from "@/components/DatePicker"
import moment from "moment";

export default {
  name: "DateTimePicker",

  components : {DatePicker},

  props: {
    label: { type: String, required: true },
    value: { type: [String, Date], default: "" },
    isStart: { type: Boolean, default: true },
    moduleName: { type: String, default: 'scheduler' },
  },
  created(){
    const { date, hour, minute } = this.extractDateTimeComponents(this.value);
    this.dateValue = date;
    this.selectedHour = hour;
    this.selectedMinute = minute;
    this.handleChanges();
  },

  data() {
    return {
      dateTimeStr: null,
      dateValue: null,
      timeValue: null,
      combinedDateTime: null,
      selectedHour: null,
      selectedMinute: null,
      displayedTime: '',
      displayedDateTime: '',
      hours: this.generateHourOptions(),
      minuteIntervals: this.generateMinuteIntervals(),
    };
  },

  methods: {

    generateHourOptions() {
      return Array.from({ length: 24 }, (_, i) => i < 10 ? `0${i}` : `${i}`);
    },
    generateMinuteIntervals() {
      return Array.from({ length: 12 }, (_, i) => (`0${i * 5}`).slice(-2));
    },
    handleTimeChange() {
      this.displayedTime = `${this.selectedHour}:${this.selectedMinute}`;
      this.handleChanges();
    },

    combineDateAndTime() {
      if (this.displayedTime === '') {
        this.handleTimeChange();
      }

      const formattedDate = moment(this.dateValue).format('YYYY-MM-DD');
      
      this.displayedDateTime = `${formattedDate} ${this.displayedTime}`;

      return this.displayedDateTime;
    },

    handleDateChanges(val){
      this.dateValue = val;
      this.handleChanges()
    },
    handleChanges() {
      this.combinedDateTime = this.combineDateAndTime();
      if(this.isStart) {
          this.$store.dispatch(`${this.moduleName}/setStart`, this.combinedDateTime);
      } else {
          this.$store.dispatch(`${this.moduleName}/setEnd`, this.combinedDateTime)
      }
      this.$emit('inputDateTimePart', this.combinedDateTime);
    },
    
    extractDateTimeComponents(dateStr) {
      const momentDate = moment(dateStr);
      const hour = momentDate.format('HH');
      const minute = momentDate.format('mm');

      return {
        date: momentDate,
        hour,
        minute
      };
    },
  }
};
</script>

<style scoped>
.time-picker-container {
  margin: 10px;
}

.time-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>

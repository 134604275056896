<template>
  <v-container pa-0 ma-0>
    <v-row>
      <CategoryForm></CategoryForm>
      <v-col v-show="askStockPart" cols="12" sm="6" md="6">
        <v-autocomplete
          v-model="editedItem.stock_part_id"
          :items="$store.state.stock_parts.search"
          item-title="name"
          item-value="pk"
          :label="$filters.capitalize($t('stock_parts.label') )"
          v-model:search="autocompleteSearchStockPart"
          :loading="$store.state.stock_parts.isSearching"
          append-icon="mdi-text-box-search"          
          @update:modelValue="stockPartSelected"
          variant="solo"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{$filters.capitalize(
                  $t("search_for", { noun: $t("stock_parts.label", 1) })
                    )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{item, props:{onClick}}">
            <v-list-item @click="onClick">
              <span
                v-if="$store.getters.language === 'en' && item.raw.ref !== '' && item.raw.name_en != ''">
                  {{ item.raw.name_en }} -{{ item.raw.ref }}
              </span>
              <span
                v-else-if="$store.getters.language === 'en' && item.raw.ref === '' && item.raw.name_en != ''">
                  {{ item.raw.name_en }}
              </span>
              <span v-else-if="$store.getters.language !== 'en' && item.raw.ref !== '' ">
                {{ item.raw.name }} - {{ item.raw.ref }}
              </span>
              <span v-else>
                {{ item.raw.name }}
              </span>
            </v-list-item>
          </template>
          <template v-slot:selection="{item}">
            <span
                v-if="$store.getters.language === 'en' && item.raw.ref !== '' && item.raw.name_en != ''">
                  {{ item.raw.name_en }} -{{ item.raw.ref }}
              </span>
              <span
                v-else-if="$store.getters.language === 'en' && item.raw.ref === '' && item.raw.name_en != ''">
                  {{ item.raw.name_en }}
              </span>
              <span v-else-if="$store.getters.language !== 'en' && item.raw.ref !== '' ">
                {{ item.raw.name }} - {{ item.raw.ref }}
              </span>
              <span v-else>
                {{ item.raw.name }}
              </span>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-autocomplete
          :disabled="!askPlace"
          v-model="editedItem.place"
          :items="$store.state.places.search"
          :item-title="formatTextTitle"
          item-value="pk"
          :label="$filters.capitalize($t('places.label', 1) )"
          v-model:search="autocompleteSearchPlace"
          :loading="$store.state.places.isSearching"
          :filter="filterPlaces"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          cache-items
          return-object
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{$filters.capitalize(
                  $t("search_for", { noun: $t("places.label", 1) })
                    )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template #item="{item, props:{onClick}}">
            <v-list>
              <v-list-item :title="item.raw.shelf != null ?
              item.raw.stockage.name + ' - ' + item.raw.cabinet + '/'+ item.raw.shelf
              : item.raw.stockage.name + ' - ' + item.raw.cabinet"
              @click="onClick"
              />
            </v-list>        
          </template>
          <template v-slot:selection="{item}">
            <v-list-item :title="item.raw.shelf != null ?
              item.raw.stockage.name + ' - ' + item.raw.cabinet + '/'+ item.raw.shelf
              : item.raw.stockage.name + ' - ' + item.raw.cabinet"
              />
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="editedItem.serial"
          :label="$filters.capitalize($t('units.serial', 1) )"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CategoryForm from "@/components/CategoryForm"
import filters from "@/mixins/filters";
import { isNone } from "@/functions.js";

export default {
  mixins: [filters],

  components: {
    CategoryForm: CategoryForm,
  },

  props: {
    askStockPart: { type: Boolean, required: false, default: true },
    askPlace: { type: Boolean, required: false, default: true },
    partFilter: { type: String, required: false, default: "" }, // un, pi, ...
    producerId: { type: Number, required: false, default: undefined },
    stockageId: { type: Number, required: false, default: undefined },
    placeId: { type: Number, required: false, default: undefined },
    stockPartId: { type: Number, required: false, default: undefined },
    producerTypeId: { type: Number, required: false, default: undefined },
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit("units");
    },
  },

  mounted() {
    if (this.producerId) {
      this.$store.dispatch("part_categories/getItemsFromProducer", [
        this.producerId,
      ]);
      this.updatePlace("");
    } else {
      this.$store.dispatch("part_categories/getItems", { size: 40 });
    }
    if (!isNone(this.placeId) && this.askPlace) {
      this.editedItem.place_id = this.placeId;
      this.$store.dispatch("places/initSearch", { item_ids: this.placeId });
    }
    if (!isNone(this.stockPartId)) {
      this.editedItem.stock_part_id = this.stockPartId;
    }
    if (!this.askStockPart) {
      // in that case, add reference button is permitted by default
      this.serialSelection = true;
    }
  },

  methods: {
    formatTextTitle(item){
      return item.shelf != null ? item.stockage.name + ' - ' + item.cabinet + '/'+ item.shelf:  item.stockage.name + ' - ' + item.cabinet    
    },

    updateCategory(val) {
      if(val){
        var payload = { value: val, size: 10 };
        this.$store.dispatch("part_categories/searchItems", payload);
      }    
    },

    updateStockPart(val) {
      var payload = { value: val, size: 50, category_id: this.category };
      if (!isNone(this.producerTypeId)) {
        payload["producer_types"] = this.producerTypeId;
      }
      if (!isNone(this.partFilter)) {
        payload["unity"] = self.partFilter;
      }
      this.$store.dispatch("stock_parts/searchItems", payload);
      
    },

    updatePlace(val) {
      if (this.producerId !== undefined) {
        this.$store.dispatch("places/searchItemsFromProducer", {
          producer_id: this.producerId,
          value: val,
        });
      } else {
        var payload = { value: val };
        if (this.stockageId !== undefined) {
          payload["stockage_id"] = this.stockageId;
        }
        this.$store.dispatch("places/searchItems", payload);
      }
    },

    stockPartSelected(item) {
      if (!isNone(item)) {
        this.editedItem.stock_part_id = item;
        this.serialSelection = item.unity === "un";
      } else {
        this.serialSelection = false;
      }
      this.$store.dispatch("stock_parts/updateEdit", item);
    },
  },

  watch: {
    autocompleteSearchCategory(val) {
      if (!this.$store.getters.canSearch("part_categories")) return;
      if (!this.category) {
        this.updateCategory(val);
      }
    },
    autocompleteSearchPlace(val) {
      if (!this.$store.getters.canSearch("places")) return;
      this.updatePlace(val);
    },
    autocompleteSearchStockPart(val) {
      if (!this.$store.getters.canSearch("stock_parts")) return;
      if (!this.editedItem.stock_part_id) {
        this.updateStockPart(val);
      }
    },
    category() {
      if (isNone(this.editedItem.stock_part_id)) {
        this.updateStockPart("");
      }
    },
  },

  data() {
    return {
      autocompleteSearchPlace: null,
      autocompleteSearchStockPart: null,
      serialSelection: false,
    };
  },
};
</script>

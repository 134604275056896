/* eslint-disable no-unused-vars */
import i18n from "@/i18n";
import moment from "moment";
import numeral from "numeral";
import swal from "sweetalert";
import { isProxy, toRaw } from "vue";

export function capitalize(value) {
  // Capitalize a string
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function formatAvailability(value) {
  const val = numeral(value);
  if (isNone(value) || isNone(val)) {
    return "?";
  }
  return val.format("0");
}

export function formatBool(value) {
  if (value) {
    return i18n.global.t("generic.yes");
  }
  return i18n.global.t("generic.no");
}

export function formatCategory(value) {
  if (isNone(value) || isNone(value.producer_types)) {
    return "";
  }
  var categories = value.producer_types.map((x) => x.name);
  if (categories.length === 0) {
    categories = [capitalize(i18n.global.t("part_categories.generic"))];
  }
  return `${value.name} (${categories.join(", ")})`;
}

export function formatCategoryEn(value) {
  if (isNone(value) || isNone(value.producer_types)) {
    return "";
  }
  var categories = value.producer_types.map((x) => x.name);
  if (categories.length === 0) {
    categories = [capitalize(i18n.global.t("part_categories.generic"))];
  }
  return `${value.name_en} (${categories.join(", ")})`;
}

export function formatDat(value) {
  if (isNone(value)) {
    return "";
  }
  return moment(value).format("DD/MM");
}

export function formatDate(value) {
  if (isNone(value)) {
    return "";
  }
  return moment(value).format("DD/MM/YYYY");
}

export function formatDateTime(value) {
  if (isNone(value)) {
    return "";
  }
  return moment(value).format("DD/MM/YYYY HH:mm");
}

export function formatFullDateTime(value) {
  if (isNone(value)) {
    return "";
  }
  return moment(value).format("DD/MM/YYYY HH:mm:ss");
}

export function formatTime(value) {
  if (isNone(value)) {
    return "";
  }
  return moment(value).format("HH:mm");
}

export function formatFloat(value) {
  const val = numeral(value);
  if (isNone(value) || isNone(val)) {
    return "?";
  }
  return val.format("0.0");
}

export function formatOrigin(value) {
  if (isNone(value)) {
    return "";
  }
  return i18n.global.t(`limitations.origin.${value}`);
}

export function formatPercentage(value) {
  var val = numeral(value);
  if (isNone(value) || isNone(val)) {
    return "?";
  }
  val = numeral(value * 100);
  return val.format("0.0");
}

export function formatPeriod(value) {
  switch (value) {
    case "an":
      return i18n.global.t("preventive_maintenances.period.annual");
    case "bian":
      return i18n.global.t("preventive_maintenances.period.biannual");
    case "quart":
      return i18n.global.t("preventive_maintenances.period.quarterly");
    case "four":
      return i18n.global.t("preventive_maintenances.period.four");
    default:
      return i18n.global.t("preventive_maintenances.period.unknown");
  }
}

export function formatPlace(item) {
  if (!item.stockage) return ''
  let place = item.stockage.name + " - "
  if (item.shelf) return (place + item.cabinet + "/" + item.shelf)
  return (place + item.cabinet)
}

export function formatPrice(value, currency) {
  var prefix = null;
  var postfix = null;
  switch (currency) {
    case "EUR":
      postfix = "€";
      break;
    case "USD":
      postfix = "$US";
      break;
    case "NAD":
      prefix = "N$";
      break;
    case "KMF":
      postfix = "KMF";
      break;
    default:
      console.log(`Unknown currency ${currency}.`);
  }
  var ret = formatFloat(value);
  if (prefix !== null) {
    ret = `${prefix} ${ret}`;
  }
  if (postfix !== null) {
    ret = `${ret} ${postfix}`;
  }
  return ret;
}

export function formatVariety(value) {
  if (value === "s") {
    return i18n.global.t("variety.solar");
  } else if (value === "w") {
    return i18n.global.t("variety.wind");
  }
  return i18n.global.t("variety.all");
}

export function affectationKindFilter(value) {
  // Convert affectation kind to full string
  if (value === "er") return i18n.global.t("affectations.status.external");
  else if (value === "ir") return i18n.global.t("affectations.status.internal");
  else if (value === "ss") return i18n.global.t("affectations.status.stock");
  else if (value === "ts") return i18n.global.t("affectations.status.trash");
  else if (value === "p") return i18n.global.t("affectations.status.producer");
  return "";
}

export function criticalityFilter(value) {
  // Convert criticality to full string
  if (value === "crit") return i18n.global.t("alarm_codes.criticality.critical");
  else if (value === "warn") return i18n.global.t("alarm_codes.criticality.warning");
  else if (value === "ok") return i18n.global.t("alarm_codes.criticality.ok");
  return "";
}

export function reportStatusFilter(value) {
  // Convert intervention report status values to full string
  if (value === "tmp") return i18n.global.t("intervention_reports.status.tmp");
  else if (value === "final")
    return i18n.global.t("intervention_reports.status.final");
  else if (value === "maintenance")
    return i18n.global.t("intervention_reports.status.maintenance");
  else if (value === "none") return i18n.global.t("intervention_reports.status.none");
  return "";
}

export function unityFilter(value) {
  // Convert unity values to full string
  if (value === "box") return i18n.global.t("unity.box");
  else if (value === "l") return i18n.global.t("unity.liter");
  else if (value === "m") return i18n.global.t("unity.meter");
  else if (value === "kg") return i18n.global.t("unity.kg");
  else if (value === "pi") return i18n.global.t("unity.piece");
  else if (value === "un") return i18n.global.t("unity.unit");
  return "";
}

export function varietyFilter(value) {
  // Convert variety values to full string
  if (value === "s") return i18n.global.t("variety.solar");
  else if (value === "w") return i18n.global.t("variety.wind");
  return "";
}

export function extractFilename(headers, default_ = "file") {
  // Read filename from a downloaded file stream
  let headerLine = headers["content-disposition"];
  const fnReg = /filename="(.+)"/g;
  var match = fnReg.exec(headerLine);
  var filename = default_;
  if (match !== null && match.length > 1) {
    filename = match[1];
  }

  return filename;
}

export function isNone(value) {
  // Check if a value is null in a wild sens
  return ["", null, undefined, NaN].includes(value);
}

export function isEmpty(value) {
  // Check if a value is an empty list or None (as defined before)
  return value == [] || isNone(value);
}

export function slicyUpdate(array_, item) {
  // Create or Update array item (based on pk key match)
  // Usefull to trigger front update
  const index = array_.findIndex((obj) => obj.pk === item.pk);
  if (index !== -1) {
    array_.splice(index, 1);
    array_.splice(index, 0, item);
  } else {
    array_.push(item);
  }
}

export function slicyRemove(array_, item) {
  // Remove array item (based on pk key match)
  // Usefull to trigger front update
  const index = array_.findIndex((obj) => obj.pk === item.pk);
  if (index !== -1) {
    array_.splice(index, 1);
  }
}

export function concat(...values) {
  // Concatenate all inputed elements with a full "cadratin"
  return [...values].join(" — ");
}

export function isUnit(stock_part) {
  // Check if a stock part is a unit (aka with serial number)
  return stock_part.unity === "un";
}

export function endCorrection(array) {
  var array_ = array;
  // adapt end time to bypass Bryntum scheduler tz manipulation error
  for (let i = 0; i < array.length; i++) {
    const start = moment(array_[i].startDate);
    const end = moment(array_[i].endDate);
    if (
      start.format("mmss") === "0000" &&
      end.format("mmss") === "5959" &&
      end.hour() <= 3
    ) {
      // Whole day events can have a shifted end due to Scheduler bug on tz manipulation
      // we correct that specific new events
      array_[i].endDate = end.subtract(1, "days").endOf("day");
    }
  }
  return array_;
}

export function sumValues(items) {
  var val = 0.0;
  Object.values(items).forEach((value) => {
    val += value;
  });
  return val;
}

export function alertPopup(text) {
  return swal({
    text: text,
    icon: "warning",
    buttons: [capitalize(i18n.global.t("cancel")), "OK"]
  });
}

export function unProxy(value) {
  if (isProxy(value)) {
    value = toRaw(value);
  }
  return value;
}

<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="editedItem.category_id"
          :items="$store.state.part_categories.search"
          :item-title='$store.getters.language === "en"? $filters.formatCategoryEn :$filters.formatCategory '
          item-value="pk"
          :label="$filters.capitalize($t('part_categories.label'))"
          v-model:search="autocompleteSearchCat"
          :loading="$store.state.part_categories.isSearching"
          :filter="filterPartCategory"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          cache-items
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("part_categories.label", 1) })
                  )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{item, props:{onClick}}">
            <v-list-item @click="onClick">
            <span v-if="$store.getters.language === 'en'">
              {{$filters.formatCategoryEn( item.raw ) }}
            </span>
            <span v-else>
              {{$filters.formatCategory( item.raw ) }}
            </span></v-list-item>
          </template>
          <template v-slot:selection="data">
            <span v-if="$store.getters.language === 'en'">
              {{$filters.formatCategoryEn( data.item.raw ) }}
            </span>
            <span v-else>
              {{$filters.formatCategory( data.item.raw ) }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";

export default {
  mixins: [filters],

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  watch: {
    autocompleteSearchCat(val) {
      if (!this.$store.getters.canSearch("part_categories")) return;
      this.$store.dispatch("part_categories/searchItems", { value: val });
    },
  },

  data() {
    return {
      moduleName: "stock_parts",
      autocompleteSearchCat: undefined,
    };
  },
};
</script>

import crud from "./crud";
import crud_service from "@/services/crud";

const path = "power_stations";
const struct = {
  name: "",
  power: 0.0,
  description: "",
  applicable_maintenances: [],
  stockage_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  getPreventiveMaintenances({ commit }) {
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/preventive_maintenances`)
        .then((items) => {
          //commit('setItems', items)
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

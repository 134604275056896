<template>
  <v-container>
    <div style="display: none">
      <EditDialog
        v-bind:model="editLimitation"
        v-bind:moduleName="'limitations'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('limitations.label')"
        v-bind:dataValidation="validateLimitation"
        @save="saveEvent"
        @close="cleanupDialog"
        ref="editDialog"
      >
        <template v-slot:edit_content>
          <LimitationForm></LimitationForm>
        </template>
      </EditDialog>
    </div>
    <h3 class="ml-4 mt-2">
      <v-icon>mdi-tortoise</v-icon>
      {{ $filters.capitalize($t(`${moduleName}.label`)) }}
    </h3>
    <LimitationSearch @filterToP="changeP($event)"/>
    <v-card class="mt-3">
      <v-btn
        color="primary"
        elevation="3"
        class="ml-3 mb-5"
        @click="newLimitation"
        variant="outlined"
      >
        {{ $filters.capitalize($t(`${moduleName}.actions.add_item`)) }}
      </v-btn>

      <v-btn
        color="primary"
        elevation="3"
        class="ml-3 mb-5"
        @click="toggleView"
        variant="outlined"
      >
        {{ isListView ? 'Switch to Calendar View' : 'Switch to List View' }}
      </v-btn>

      <template v-if="isListView">
        <v-data-table-server
        :headers="headers"
        :items="limitations_data"
        item-key="pk"
        :items-per-page-text='$t("rows_per_page")'
        :items-per-page-options='itemsPerPageOptions'
        :items-length="pagination.size || 0"
        :items-per-page="pagination.page_size"
        :page="pagination.current"
        :loading="$store.state.limitations.isLoading"
        density="compact"
        :no-data-text="$filters.capitalize($t('no_data_text'))"
        :no-results-text="$filters.capitalize($t('no_results_text'))"
        @update:options="loadOptions">
        <template v-slot:item.start="{ item }">{{ $filters.formatDateTime(
          item.start)
          }}
        </template>
        <template v-slot:item.end="{ item }">{{ $filters.formatDateTime(
          item.end)
          }}
        </template>
        <template v-slot:item.duration="{ item }">{{ calculateDuration(
          item.start, item.end)
          }}
        </template>
        <template v-slot:item.impact_dispo="{ item }">{{ $filters.formatBool(
          item.impact_dispo)
          }}
        </template>
        <template v-slot:item.origin="{ item }">{{ $filters.formatOrigin(
          item.origin)
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip location="top" :text="$filters.capitalize($t('actions.edit'))">
            <template v-slot:activator="{ props }">
              <v-icon
                v-if="isFromStaff"
                v-show="
                  item.origin !== 'deie' ||
                  (item.origin === 'deie' && item.end !== undefined)
                "
                small
                v-bind="props"
                class="mr-2"
                @click="openEdit(item)"
              >mdi-pencil
              </v-icon>
            </template>
          </v-tooltip>
          <v-tooltip location="top" :text="$filters.capitalize($t('actions.delete')) ">
            <template v-slot:activator="{ props }">
              <v-icon
                v-if="isFromStaff"
                v-show="item.origin !== 'deie'"
                small
                v-bind="props"
                @click="deleteBtn(item.pk)"
              >mdi-delete
              </v-icon
              >
            </template>
          </v-tooltip>
        </template>
      </v-data-table-server>
      </template>
      <template v-else>
        <CalendarView :events="events" />
      </template>
    </v-card>
  </v-container>
</template>

<script>
import { isNone, capitalize, alertPopup, slicyUpdate, slicyRemove } from "@/functions";
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import LimitationForm from "@/components/LimitationForm.vue";
import EditDialog from "@/components/EditDialog";
import CalendarView from "@/components/LimitationCalendarView.vue";
import LimitationSearch from "@/components/limitations/LimitationSearch.vue";

export default {
  mixins: [utils, filters],
  components: { LimitationForm, EditDialog, CalendarView, LimitationSearch },

  methods: {
    changeP(search_params) {
      this.search_params = search_params;
      this.loadList();
    },
    toggleView() {
      this.isListView = !this.isListView;
    },
    transformItemsToEvents() {
      this.events = this.items.map(item => ({
        title: item.producer.display_name,
        start: item.start,
        end: item.end,
        extendedProps: { ...item }
      }));
    },
    async loadList() {
      if (this.$store.state.limitations.isLoading) return;

      const payload = {
        ...this.paginationOptions,
        ...this.search_params
      };
      const response = await this.$store.dispatch('limitations/getItems', payload);
      this.limitations_data = response;
      this.transformItemsToEvents();
      if(!this.isListView){  //TODO Search in View Calendar
        this.isListView = true;
      }
    },

    loadOptions({page, itemsPerPage, sortBy}){
        this.paginationOptions = this.genPaginationPayload(
            {"page": page, "size": itemsPerPage, "sortBy":sortBy})
        this.loadList();
    },

    openEdit(item) {
      Promise.all([
        this.$store.dispatch(`${this.moduleName}/updateEdit`, item),
        this.$store.dispatch("producers/updateEdit", item.producer)
      ]).then(() => {
        this.editLimitation = true;
      });
    },
    deleteBtn(pk) {
      alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
        if(result){
          this.$store.dispatch(`${this.moduleName}/deleteItem`, pk).then(() => {
          this.editLimitation = false;
        });
        const index = this.limitations_data.findIndex((i) => i.pk === pk);
        this.limitations_data.splice(index, 1);
        }
      });
    },
    saveEvent() {
      if (!isNone(this.editedItem.producer_ids)) {
        // Only a new limitation can have multiple producers
        let req_array = [];
        let updatedItems = [];

        for (const p of this.editedItem.producer_ids) {
          const updatedItem = { ...this.editedItem };
          updatedItem.producer_id = p.pk;
          updatedItem.producer = p;
          updatedItem.producer_ids = null;
          req_array.push(
            this.$store.dispatch(`${this.moduleName}/addItem`, updatedItem)
          );
          updatedItems.push(updatedItem);
        }

        Promise.all(req_array).then((results) => {
          results.forEach((result) => {
            this.addToLimitationData(result);
          });
          this.editLimitation = false;
        }).catch((error) => {
          console.error("An error occurred:", error);
        });
      } else {
        if (isNone(this.editedItem.pk)) {
          // new limitation
          this.$store
            .dispatch(`${this.moduleName}/addItem`, this.editedItem)
            .then(() => {
              this.editLimitation = false;
            });
            this.addToLimitationData(this.editedItem);

        } else {
          // if there is a pk it is an existing limitation
          this.$store
            .dispatch(`${this.moduleName}/editItem`, this.editedItem)
            .then(() => {
              this.editLimitation = false;
              slicyUpdate(this.limitations_data, this.editedItem);
            });
        }
      }
    },
    addToLimitationData(item) {
      const search_start = this.search_params.start;
      const search_end = this.search_params.end;
      const search_producer_id = this.search_params.producer_id;
      const search_origin = this.search_params.origin_options;
      let isWithinRange = new Date(item.start) >= search_start && new Date(item.end) <= search_end;
      let matchesProducerId = search_producer_id.includes(item.producer_id);
      let isInOrigin = search_origin.includes(item.origin);
      if (search_producer_id.length === 0) {
        matchesProducerId = true;
      }
      if (search_origin.length === 0) {
        isInOrigin = true;
      }
      if (search_start == null && search_end == null) {
        isWithinRange = true;
      }  
      if (isWithinRange && matchesProducerId && isInOrigin) {
        this.limitations_data.push(item);
      } else {
        console.log('Item does not meet the criteria and will not be added.');
      }
    },
    cleanupDialog() {
      // this.$store.dispatch("limitations/cleanupEdit");
      this.editLimitation = false;
    },
    newLimitation() {
      Promise.all([
        this.$store.dispatch("limitations/cleanupEdit"),
        this.$store.dispatch("producers/cleanupEdit")
      ]).then(() => {
        this.editLimitation = true;
      });
    },
    validateLimitation() { 
        const lim_ = this.$store.state.limitations.edit
// TODO: handle empty thing (not an object, not any of the keys)?
        if (lim_.start > lim_.end) return capitalize(this.$t("limitations.date_error"))
        if (isNone(lim_.producer_id) && isNone(lim_.producer_ids)) {
            return capitalize(this.$t("limitations.missing.producer"))
        }
        if (lim_.start < new Date().setHours(0, 0, 0, 0)) {
            return capitalize(this.$t("limitations.impact_dispo.past"))
        }
        return
    },
    calculateDuration(start, end) {
      if (!start || !end) return '';
      const startDate = new Date(start);
      const endDate = new Date(end);
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
      return `${diffDays}d ${diffHours}h ${diffMinutes}m`;
    }
  },
  computed: {
    ...utils.computed
  },
  data() {
    return {
      limitations_data: [],
      itemsPerPageOptions: [10, 20, 50, 100],
      isListView: true,
      moduleName: "limitations",
      editLimitation: false,
      paginationOptions: {
        "page": 1,
        "size":100
      },

      headers: [
        {
          title: this.$filters.capitalize(this.$t("producers.label", 1)),
          key: "producer.display_name",
          sortable: true
        },
        {
          title: this.$filters.capitalize(this.$t("limitations.power_limit")),
          key: "power_limit",
          sortable: true
        },
        {
          title: this.$filters.capitalize(this.$t("limitations.start")),
          key: "start",
          sortable: true
        },
        {
          title: this.$filters.capitalize(this.$t("limitations.end")),
          key: "end",
          sortable: true
        },
        {
          title: this.$filters.capitalize(this.$t("limitations.duration")),
          key: "duration",
          sortable: false
        },
        {
          title: this.$filters.capitalize(this.$t("limitations.impact_dispo.label")),
          key: "impact_dispo",
          sortable: true
        },
        {
          title: this.$filters.capitalize(this.$t("limitations.comment")),
          key: "comment",
          sortable: false
        },
        {
          title: this.$filters.capitalize(this.$t("limitations.origin.label")),
          key: "origin",
          sortable: true
        },
        {
          title: this.$filters.capitalize(this.$t("actions.label")),
          key: "actions",
          sortable: false
        }
      ]
    };
  }
};
</script>

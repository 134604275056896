<template>
  <v-container>
    <div style="display: none">
      <EditDialog
        v-bind:model="editAffectation"
        v-bind:moduleName="'affectations'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('edit_item')"
        @save="saveEvent()"
        @close="cleanupDialog()"
        ref="editDialogAff"
      >
        <template v-slot:edit_content>
          <AffectationForm
            v-bind:askKind="false"
            @save="saveEvent()"
            v-bind:showSerial="false"
          ></AffectationForm>
        </template>
      </EditDialog>
      <SimpleDialog
        v-if='isFromStaff'
        v-bind:model="editNdS"
        v-bind:formTitle="$t('units.actions.edit_serial')"
        v-bind:btnName="''"
        @add="updateNdS()"
        @close="cleanupNdSDialog()"
      >
        <template v-slot:content>
          <v-text-field variant="solo"
            v-model="$store.state.parts.edit.serial"></v-text-field>
          <v-btn color="primary darken-1"
            class="mb-2"
            @click.stop="updateNdS"> {{ $filters.capitalize($t("save")) }}
          </v-btn>
        </template>
      </SimpleDialog>
    </div>

    <!-- ========================== End dialogs ======================== -->
    <v-toolbar ext color="white" elevation="1" class="my-3">
      <v-spacer></v-spacer>
      <v-toolbar-title style="font-weight: bolder" class="center pa-2 pb-3">{{ $filters.capitalize(
        $t(pageTitle, 1))
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip location="top" :text="$filters.capitalize($t(`actions.edit`))">
        <template v-slot:activator="{ on }">
          <v-icon
            v-show="part ? true : false"
            v-bind="on"
            class="mr-2"
            @click="toEditBtn()"
          >mdi-pencil-outline
          </v-icon
          >
        </template>
      </v-tooltip>
      <v-tooltip location="top" :text="$filters.capitalize($t(`stockages.label`, 1)) ">
        <template v-slot:activator="{ on }">
          <v-icon
            v-show="toListEnabled"
            v-bind="on"
            class="mr-2"
            @click="toListBtn()"
          >mdi-view-list
          </v-icon
          >
        </template>
      </v-tooltip>
      <v-tooltip location="top" :text="$filters.capitalize($t(`intervention_reports.label`, 10))">
        <template v-slot:activator="{ on }">
          <v-icon
            v-show="toMachineEnabled"
            v-bind="on"
            class="mr-2"
            @click="toMachineBtn()"
          >mdi-lightning-bolt-circle
          </v-icon
          >
        </template>
      </v-tooltip>
    </v-toolbar>
    <!-- TODO: make this v-card a reusable component - share with editpartform -->
    <v-card class="px-2">
      <v-card-title>{{ $filters.capitalize($t("stock_parts.label")) }}</v-card-title>
      <v-card-text v-if="$store.state.parts.edit.stock_part">
        <v-row density= 'compact'>
          <v-col>
            <div>{{ $filters.capitalize($t("producer_types.label", 1)) }}</div>
            <ul class='ml-4'
              v-if="
                $store.state.parts.edit.stock_part.category.producer_types !=
                undefined
              "
            >
              <li
                v-for="producer in $store.state.parts.edit.stock_part.category
                  .producer_types"
                v-bind:key="producer.pk"
              >
                {{ $filters.capitalize(producer.name) }}
              </li>
            </ul>
            <ul>
              <li
                v-if="
                  $store.state.parts.edit.stock_part.category.producer_types
                    .length == 0
                "
              >
                {{ $filters.capitalize($t("part_edition.generic_type")) }}
              </li>
            </ul>
          </v-col>
          <v-col>
            <div>{{ $filters.capitalize($t("part_categories.label")) }}</div>
            <p class="text-body-1 text--primary">
              {{ $store.state.parts.edit.stock_part.category.name }}
            </p>
          </v-col>
        </v-row>

        <v-row density= 'compact'>
          <v-col>
            <div>{{ $filters.capitalize($t("stock_parts.name")) }}</div>
            <p class="text-body-1 text--primary">
              {{ $store.state.parts.edit.stock_part.name }}
            </p>
          </v-col>
          <v-col>
            <div>{{ $filters.capitalize($t("stock_parts.name_en")) }}</div>
            <p class="text-body-1 text--primary">
              {{ $store.state.parts.edit.stock_part.name_en }}
            </p>
          </v-col>
          <v-col>
            <div>{{ $filters.capitalize($t("stock_parts.ref")) }}</div>
            <p class="text-body-1 text--primary">
              {{ $store.state.parts.edit.stock_part.ref }}
            </p>
          </v-col>
        </v-row>
        <v-row density= 'compact'>
          <v-col>
            <div>{{ $filters.capitalize($t("unity.label")) }}</div>
            <p class="text-body-1 text--primary">
              {{ $store.state.parts.edit.stock_part.unity }}
            </p>
          </v-col>
          <v-col>
            <div>{{ $filters.capitalize($t("stock_parts.price")) }}</div>
            <p class="text-body-1 text--primary">
              {{ $store.state.parts.edit.stock_part.price }}
            </p>
          </v-col>
          <v-col>
            <div>{{ $filters.capitalize($t("generic.currency")) }}</div>
            <p class="text-body-1 text--primary">
              {{ $store.state.parts.edit.stock_part.price_currency }}
            </p>
          </v-col>
        </v-row>
        <v-row density= 'compact'>
          <v-col>
            <div>{{ $filters.capitalize($t("suppliers.label", 10)) }}</div>
            <ul class='ml-4'
              v-if="$store.state.parts.edit.stock_part.suppliers !== undefined"
            >
              <li
                v-for="supplier in $store.state.parts.edit.stock_part.suppliers"
                v-bind:key="supplier.pk"
              >
                {{ $filters.capitalize(supplier.name) }}
              </li>
            </ul>
          </v-col>
          <v-col>
            <div>{{ $filters.capitalize($t("stock_parts.is_locally_bought")) }}</div>
            <p
              class="text-body-1 text--primary"
              v-show="!$store.state.parts.edit.stock_part.is_locally_bought"
            >
              {{ $filters.capitalize($t("stock_parts.is_locally_bought_.false")) }}
            </p>
            <p
              class="text-body-1 text--primary"
              v-show="$store.state.parts.edit.stock_part.is_locally_bought"
            >
              {{ $filters.capitalize($t("stock_parts.is_locally_bought_.true")) }}
            </p>
          </v-col>
          <v-col>
            <div>{{ $filters.capitalize($t("stock_parts.image")) }}</div>
            <v-img
              :src="$store.state.parts.edit.stock_part.image"
              style="border: 1px dashed grey"
              max-height="210"
              max-width="280"
              placeholder="static/camera_off.png"
              contain
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        {{ $filters.capitalize($t("no_data_text")) }}
      </v-card-text>
    </v-card>
<div v-show=is_unit>
    <v-card class="pa-2 mt-2">
      <!-- TODO: add edit mode for staff? -->
      <v-row density= 'compact' class="pa-2 mt-2">
        <v-col
          v-if="$store.state.parts.edit.place && last_state == 'ss'"
        ><p class="text-body-1 text--primary">
          <b>{{ $filters.capitalize($t("part_edition.subtitle")) }}:</b>
          {{ $filters.capitalize($store.state.parts.edit.place.stockage.name) }} -
          {{ $filters.capitalize($store.state.parts.edit.place.name) }}
        </p></v-col
        >
        <v-col>
          <b>{{ $filters.capitalize($t("part_view.serials")) }}:</b>
          {{ $store.state.parts.edit.serial }}
          <v-icon
            v-show="isFromStaff"
            small
            class="mr-2"
            @click="editNdS=true"
          >mdi-pencil
          </v-icon
          >
        </v-col
        >
        <v-col v-if="qr_code == ''">
          <ScanCodeButton
            v-bind:btnName="'Add code'"
            v-bind:newCode="true"
            @decoded="qrCodeDecodedEvent"
          ></ScanCodeButton>
        </v-col>
        <v-col v-else>
          CODE:
          {{ qr_code }}
        </v-col>
      </v-row>
      <v-row density= 'compact' class="pa-2 mt-2 d-flex flex-wrap">
        <v-col>
          <div>
            <p class="text-body-1 text--primary">
              {{ $filters.capitalize($t("generic.created_at")) }} :
              {{ $filters.formatDate($store.state.parts.edit.created_at) }}
            </p>
          </div>
        </v-col>
        <v-col>
          <div>
            <p class="text-body-1 text--primary">
              {{ $filters.capitalize($t("generic.created_by")) }}:
              {{ $store.state.parts.edit.created_by?  $store.state.parts.edit.created_by.name : ''  }}
            </p>
          </div>
        </v-col>
        <!-- TODO: link to view purchase -->
        <!-- <v-col v-if="$store.state.parts.edit.purchase">
          <div>
            {{$filters.capitalize( $t("purchase_view.label") ) }}
          </div>
          <p class="text-body-1 text--primary">
            {{ $store.state.parts.edit.purchase }}
          </p>
        </v-col> -->
      </v-row>
    </v-card>

    <v-card class="pa-2 mt-2">
      <v-card-title>{{ $filters.capitalize(
        $t("affectations.label", 10))
        }}
      </v-card-title>
      <v-data-table
        class="hidden-sm-and-down"
        :headers="headers"
        :items="$store.state.affectations.items"
        item-key="pk"
        :no-data-text="$filters.capitalize($t('no_data_text') )"
        :hide-default-footer="$store.state.affectations.items.length < 10"
      >
        <template v-slot:item.date="{ item }">
          {{ $filters.formatDate(item.date) }}
        </template>
        <template v-slot:item.kind="{ item }">
          {{ $filters.affectationKindFilter(item.kind) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip location="top" :text="$filters.capitalize($t('actions.edit'))">
            <template v-slot:activator="{ props }">
              <v-icon small v-bind="props" class="mr-2" @click="editItemBtn(item)"
              >mdi-pencil
              </v-icon>
            </template>
          </v-tooltip>
          <v-tooltip location="top" :text="$filters.capitalize($t('actions.delete'))">
            <template v-slot:activator="{ props }">
              <v-icon small v-bind="props" class="mr-2" @click="deleteItemBtn(item)"
              >mdi-delete-empty-outline
              </v-icon>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>

      <div class="hidden-md-and-up">
        <v-card
          class="mb-3 px-2"
          v-for="item in $store.state.affectations.items"
          :key="item.pk"
        >
          <v-row density= 'compact'>
            <v-col class="text-center pt-3">{{ $filters.capitalize($filters.affectationKindFilter(
              item.kind))
              }}
            </v-col>
            <v-col v-if="item.producer" class="text-center pt-3">{{ $filters.capitalize(
              item.producer.display_name)
              }}
            </v-col>
            <v-col class="text-center pt-3">{{ $filters.formatDate(item.date) }}</v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-icon class="px-4" medium @click="editItemBtn(item)"
              >mdi-pencil
              </v-icon
              >
              <v-icon class="px-4" medium @click="deleteItemBtn(item)"
              >mdi-delete-empty-outline
              </v-icon
              >
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-card>

    <v-card class="mt-3 d-flex flex-wrap align-center justify-space-around">
      <v-card-title>{{ $filters.capitalize(
        $t("affectations.actions.add_item"))
        }}
      </v-card-title>
      <v-btn
        class="my-2"
        :disabled="last_state == 'ss' || btnsDisabled"
        @click="openDialog('ss')"
      >
        <v-icon>mdi-arrow-right-bold</v-icon>
        {{ $filters.capitalize($t("stockages.label", 1)) }}
      </v-btn>
      <v-btn
        class="my-2"
        :disabled="last_state == 'p' || btnsDisabled"
        @click="openDialog('p')"
      >
        <v-icon>mdi-arrow-right-bold</v-icon>
        {{ $filters.capitalize($t("units.machine", 1)) }}
      </v-btn>
      <v-btn
        class="my-2"
        :disabled="last_state == 'er' || btnsDisabled"
        @click="openDialog('er')"
      >
        <v-icon>mdi-arrow-right-bold</v-icon>
        {{ $filters.capitalize($t("external_repairs.label", 1)) }}
      </v-btn>
      <v-btn
        class="my-2"
        :disabled="last_state == 'ir' || btnsDisabled"
        @click="openDialog('ir')"
      >
        <v-icon>mdi-arrow-right-bold</v-icon>
        {{ $filters.capitalize($t("internal_repairs.label", 1)) }}
      </v-btn>
      <v-btn
        class="my-2"
        :disabled="last_state == 'ts' || btnsDisabled"
        @click="openDialog('ts')"
      >
        <v-icon>mdi-arrow-right-bold</v-icon>
        {{ $filters.capitalize($t("trash_state.name", 1)) }}
      </v-btn>
    </v-card>
    <div v-if="!this.part" class="mt-14 text-center">
      <ScanCodeButton @decoded="loadQRCodeEvent"></ScanCodeButton>
    </div>
</div>
<div v-show='!is_unit'>
    <v-card class="pa-2 mt-2"
 :text="$t('units.not_a_unit',1)"
>
</v-card>
</div>
  </v-container>
</template>
<script>
import filters from "@/mixins/filters";
import { isNone, alertPopup } from "@/functions";
import utils from "@/mixins/utils";
import EditDialog from "@/components/EditDialog";
import AffectationForm from "@/components/AffectationForm";
import ScanCodeButton from "@/components/qrcode/ScanCodeButton";
import SimpleDialog from "@/components/SimpleDialog.vue";

export default {
  components: {
    EditDialog: EditDialog,
    AffectationForm,
    ScanCodeButton,
    SimpleDialog,
  },
  mixins: [filters, utils],
  methods: {
    toEditBtn() {
      let query = {};
      query["stockage_id"] = this.$store.state.parts.edit.place.stockage_id;
      query["stock_part_id"] = this.$store.state.parts.edit.stock_part_id;
      this.$router.push({
        name: "part_edition",
        query: query
      });
    },

    toListBtn() {
      // button enabled only when producer_type exists and is unique
      let query = {};
      query["stockage_id"] = this.$store.state.parts.edit.place.stockage_id;
      query["producer_type_id"] =
        this.$store.state.parts.edit.stock_part.category.producer_type_ids[0];
      this.$router.push({ name: "part_view", query: query });
    },

    toMachineBtn() {
      let query = {};
      query["producer_id"] =
        this.$store.state.affectations.items[0].producer_id;
      this.$router.push({
        name: "intervention_reports",
        query: query
      });
    },

    openDialog(kind) {
      this.cleanupDialog();
      let default_aff = {
        kind: kind,
        ...this.$store.state["affectations"].edit
      };
      default_aff["unit_id"] = this.part;
      default_aff["date"] = new Date().toISOString();
      this.$store.dispatch("affectations/updateEdit", default_aff).then(() => {
        this.$store
          .dispatch("updateAffectationTarget", {
            type: kind,
            id: 1
          })
          .then(() => {
            this.editAffectation = true;
          });
      });
    },

    saveEvent() {
      this.btnsDisabled = true;
      this.$store
        .dispatch(`affectations/affect`, this.$store.state.affectations.edit)
        .then(() => {
          this.getAffectations();
          this.$refs.editDialogAff.closeDialog();
        });
    },

      updateNdS(){
        let obj_clean = this.$store.state.parts.edit
// TODO: check ids and take out objects before sending to back
//        Object.keys(obj_clean).forEach(key=>{console.log(obj_clean[key], typeof(obj_clean[key]))})
        let edit_object = { 
            created_by_id: this.$store.state.parts.edit.created_by.pk || this.$store.state.parts.edit.created_by.id, 
            quantity: null,
            ...obj_clean}
        edit_object.quantity = null
        this.$store.dispatch('parts/editItem', edit_object).then(()=>{this.editNdS=false})
    },

    cleanupNdSDialog(){
      this.$store.dispatch('parts/getItem', this.$store.state.parts.edit.pk).then(()=>{this.editNdS=false})
    },

    cleanupDialog() {
      this.editAffectation = false;
      this.$store.dispatch(
        `affectations/updateEdit`,
        this.$store.state["affectations"].struct
      );
      this.$store.dispatch(
        `internal_repairs/updateEdit`,
        this.$store.state["internal_repairs"].struct
      );
      this.$store.dispatch(
        `external_repairs/updateEdit`,
        this.$store.state["external_repairs"].struct
      );
      this.$store.dispatch(
        `producers/updateEdit`,
        this.$store.state["producers"].struct
      );
      // this.$store.dispatch(
      //   `stockages/updateEdit`,
      //   this.$store.state["stockages"].struct
      // );
    },

    getAffectations() {
      this.$store
        .dispatch("affectations/getItems", { unit_id: this.part })
        .then(() => {
          this.btnsDisabled = false;
        });
    },

    editItemBtn(item) {
      this.$store.dispatch("affectations/updateEdit", item);
      switch (item.kind) {
        case "ir":
          this.$store
            .dispatch("internal_repairs/getItem", item.internal_repair_id)
            .then(() => {
              this.editAffectation = true;
            });
          break;
        case "er":
          this.$store
            .dispatch("external_repairs/getItem", item.external_repair_id)
            .then(() => {
              this.editAffectation = true;
            });
          break;
        case "p":
          this.$store
            .dispatch("producers/getItem", item.producer_id)
            .then(() => {
              this.editAffectation = true;
            });
          break;
        // // TODO:
        // case "ss":
        // // this.$store.dispatch("stockages/getItem", item.stockage_id).then(()=> {
        // this.editAffectation = true;
        // }
        // break;
        default:
          this.editAffectation = true;
      }
    },

    deleteItemBtn(item) {
      alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
        if (result) {
          this.$store.dispatch("affectations/deleteItem", item.pk).then(() => {
            this.$store.dispatch(
              "snackbar/showSuccess",
              this.$t("snack.update_success")
            );
          });
        }
      });
    },

    qrCodeDecodedEvent(code) {
      this.$store
        .dispatch("unit_codes/assign_code", {
          qr_code: code,
          unit_id: this.part
        })
        .then((r_) => {
          if (r_.qr_code != code) {
            this.$store.dispatch(
              "snackbar/showError",
              "unit already has a code assigned"
            );
            this.qr_code = "";
            return;
          }
          if (r_.unit_id != this.part) {
            this.$store.dispatch(
              "snackbar/showError",
              "qr code is already assigned"
            );
            this.qr_code = "";
            return;
          }
          this.qr_code = code;
        });
    },

    loadQRCodeEvent(code) {
      this.qr_code = code;
      this.$store
        .dispatch("unit_codes/getItems", { qr_code: code })
        .then((d_) => {
          if (d_.length > 0) {
            this.part = d_[0].unit_id;
            this.loadUnitEvent();
          } else {
            this.$store.dispatch(
              "snackbar/showError",
              this.$t("snack.no_qr")
            );
          }
        });
    },

    loadUnitEvent() {
      this.$store.dispatch("parts/getItem", this.part).then(() => {
        this.getAffectations();
      });
    }
  },


  mounted() {
// load by QR code
    let qr = this.$route.query.qr_code;
    if (!isNone(qr)) {
      this.qr_code = qr;
      this.loadQRCodeEvent(qr);
      return;
    }
// load by part id
    this.part = parseInt(this.$route.query.part) || undefined;
    if (!isNone(this.part)) {
      this.$store
        .dispatch("unit_codes/getItems", { unit_id: this.part })
        .then((item) => {
          if (item.length > 0) {
            this.qr_code = item[0].qr_code;
          }
        });
      this.loadUnitEvent();
    }
  },

  computed: {
    last_state: function() {
      if (this.$store.state.affectations.items.length > 0)
        return this.$store.state.affectations.items[0].kind;
      return "";
    },
    toMachineEnabled: function() {
      if (isNone(this.part)) return false;
      if (
        this.$store.state.affectations.items.length > 0 &&
        this.$store.state.affectations.items[0].kind == "p"
      )
        return true;
      return false;
    },
    toListEnabled: function() {
      if (isNone(this.part)) return false;
      if (
        !isNone(this.$store.state.parts.edit) ||
        this.$store.state.parts.edit.stock_part.category.producer_type_ids.length === 1
      )
        return true;
      return false;
    },
    external_repair_edit: function() {
      return this.$store.state["external_repairs"].edit;
    },
    internal_repair_edit: function() {
      return this.$store.state["internal_repairs"].edit;
    },
    producer_edit: function() {
      return this.$store.state["producers"].edit;
    },
    is_unit: function() {
        try {
            return this.$store.state.parts.edit.stock_part.unity == 'un';
        } catch {}
    },
  },

  data() {
    return {
      pageTitle: "units.label",
      moduleName: "units",
      part: undefined,
      qr_code: "",
      editAffectation: false,
      editInternalRepair: false,
      editExternalRepair: false,
      editMachine: false,
      editStockage: false,
      editNdS:false,
      btnsDisabled: true,
      headers: [
        {
          title: this.$t("generic.date", 10),
          key: "date",
          sortable: false,
        },
        {
          title: this.$t("affectations.status.label", 10),
          key: "kind",
          sortable: false,
        },
        {
          title: this.$t("units.machine", 10),
          key: "producer.display_name",
          sortable: false,
        },
        // TODO:
        // {
        //   title: this.$t("intervention_reports.label", 1),
        //   key: "affectation",
        //   sortable: false,
        // },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        }
      ]
    };
  }
};
</script>

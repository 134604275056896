<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="8" md="6">
        <v-text-field
          v-model="editedItem.description"
          :label="$filters.capitalize($t('internal_repairs.description'))"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="8" md="6">
        <v-autocomplete
          v-model="editedItem.assigned_to_id"
          :items="$store.state.users.search"
          item-title="name"
          item-value="id"
          :label="$filters.capitalize($t('external_repairs.assigned_to', 1) )"
          v-model:search="autocompleteSearchUser"
          :loading="$store.state.users.isSearching"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          cache-items
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="8" md="6">
        <DatePicker
          v-if="askRepairedAt"
          v-bind:label="'internal_repairs.repaired_at'"
          v-bind:value="editedItem.repaired_at"
          @input="editedItem.repaired_at = $event"
        >
        </DatePicker>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import filters from "@/mixins/filters";
import DatePicker from "@/components/DatePicker";
import { isNone } from "@/functions";

export default {
  components: {
    DatePicker: DatePicker,
  },

  mixins: [filters],

  props: {
    askRepairedAt: { type: Boolean, required: false, default: true },
  },

  computed: mapState({
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  }),

  mounted() {
    if (!isNone(this.editedItem.assigned_to_id)) {
this.$store.dispatch("users/initSearch", {
        item_ids: this.editedItem.assigned_to_id,
      });
    } else {
      this.$store.dispatch("users/setSearch", this.$store.state.user);
      this.editedItem.assigned_to_id = this.$store.state.user.id;
    }
  },

  watch: {
    autocompleteSearchUser(val) {
      if (!this.$store.getters.canSearch("users")) return;
      this.$store.dispatch("users/searchItems", { value: val });
    },
    editedItem: {
      handler() {
        this.$emit("subFormUpdate", "ir");
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      moduleName: "internal_repairs",
      autocompleteSearchUser: null,
    };
  },
};
</script>

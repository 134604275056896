import crud from "./crud";
import crud_service from "@/services/crud";
import { isNone } from "@/functions";

const path = "prod_availability_months";
const struct = {
  month: null,
  value: "",
  producer_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  getHistory({ commit }, start) {
    // get prod availability month infos since...
    var payload = {};
    if (!isNone(start)) {
      payload["start"] = start.format();
    }
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/history`, payload)
        .then((items) => {
          commit("setItems", items);
          resolve(items);
        })
        .catch(() => {});
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

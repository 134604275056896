<template>
<v-container>
  <div style="display: none">
    <EditDialog
      v-bind:model="editCategory"
      v-bind:moduleName="'stock_parts'"
      v-bind:btnName="''"
      v-bind:formTitle="$t('actions.choose_category')"
      @save="saveCategoryEvent"
      @close="cleanupCategoryDialog"
      ref="editDialogCategory"
    >
      <template v-slot:edit_content>
        <CategoryForm></CategoryForm>
      </template>
    </EditDialog>
  </div>
  <v-dialog
    v-model="imageInLarge"
    max-width="90vw"
    transition="scale-transition"
    content-class="imageInLargeDialog"
    @click:outside="imageInLarge = false"
  >
    <v-btn
      class="mx-auto"
      icon="true"
      color="secondary"
      @click="imageInLarge = false"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-img :src="imageInLargeSrc" alt="" max-height="90vh"/>
  </v-dialog>

  <v-dialog
    v-model="showImportPart"
    max-width="75%"
    @click:outside="showImportPart = false"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
            $filters.capitalize($t("parts.form_title_import"))
          }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <PartForm
            v-bind:askStockPart="true"
            v-bind:stockageId="stockage_id"
            v-bind:producerTypeId="producer_type_id"
          ></PartForm>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary lighten-2"
          variant="text"
          @click="showImportPart = false"
        >{{ $filters.capitalize($t("cancel")) }}
        </v-btn
        >
        <v-btn color="success" @click="saveImportStockPart()">{{
            $filters.capitalize($t("add"))
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="showSerialsModal"
    max-width="40em"
    transition="v-expand-x-transition"
    @click:outside="showSerialsModal = false"
  >
    <v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon="true" size="small" @click="showSerialsModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-title>
        {{ $filters.capitalize($t("units.serial", 10)) }}
      </v-card-title>

      <v-card-text>
        <ul>
          <li v-for="serial in serials" :key="serial">{{ serial }}</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="showAddConsumable"
    max-width="75%"
    @click:outside="showAddConsumable = false"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
            $filters.capitalize($t("consumables.form_title"))
          }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <ConsumableForm
            v-bind:askStockPart="false"
            v-bind:stockageId="stockage_id"
            v-bind:placeId="place_id"
          ></ConsumableForm>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary lighten-2"
          variant="text"
          @click="showAddConsumable = false"
        >{{ $filters.capitalize($t("cancel")) }}
        </v-btn>
        <v-btn color="success" @click="saveAddConsumableItem()">{{
            $filters.capitalize($t("save"))
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="showAddPart"
    max-width="75%"
    @click:outside="showAddPart = false"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
            $filters.capitalize($t("units.form_title"))
          }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <PartForm
            :key="stock_part_id"
            v-bind:askStockPart="false"
            v-bind:stockageId="stockage_id"
            v-bind:placeId="place_id"
            v-bind:stockPartId="stock_part_id"
          ></PartForm>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary lighten-2"
          variant="text"
          @click="showAddPart = false"
        >{{ $filters.capitalize($t("cancel")) }}
        </v-btn
        >
        <v-btn color="success" @click="saveAddPartItem()">{{
            $filters.capitalize($t("save"))
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="showDeleteItem"
    @click:outside="showDeleteItem = false"
    width="unset"
  >
    <v-card>
      <v-card-title>
        <span class="headline mr-4">{{
            $filters.capitalize($t("parts.form_title_delete"))
          }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <PartSerialForm
            v-show="isUnit"
            v-bind:stock_part_id="editedStockPartId"
          ></PartSerialForm>
          <PartQuantityForm v-show="!isUnit"></PartQuantityForm>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary lighten-2"
          variant="text"
          @click="showDeleteItem = false"
        >{{ $filters.capitalize($t("cancel")) }}
        </v-btn
        >
        <v-btn color="success" @click="saveDeleteItem()">{{
            $filters.capitalize($t("save"))
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="helpDialog"
    @click:outside="helpDialog = false"
    width="unset"
  >
    <v-card>
      <v-card-title>
        <span class="headline mr-4">{{
            $filters.capitalize($t("help.part.title"))
          }}</span>
      </v-card-title>

      <v-card-text>
        {{ $filters.capitalize($t("help.part.unit1")) }}
        <ul>
          <li>{{ $filters.capitalize($t("help.part.unit2")) }}</li>
          <li>{{ $filters.capitalize($t("help.part.unit3")) }}</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- ========================== End dialogs ======================== -->
  <v-toolbar elevation="1" color="white" class>
    <v-btn
      id="menu-activator"
      class="hidden-md-and-up pa-2 pb-3"
      :v-show="!disableControls"
    >
      <v-icon class="ml-2">mdi-menu</v-icon>
    </v-btn>

    <v-menu activator="#menu-activator">
      <v-list class="d-flex flex-column">
        <v-btn
          v-if="isFromStaff"
          color="primary"
          class="mb-2 mx-2"
          :disabled="disableControls"
          @click.prevent="exportCsv()"
        >{{ $filters.capitalize($t(`part_view.export`)) }}
          <v-progress-circular
            class="ml-3"
            v-show="loadingExport"
            :indeterminate="true"
          ></v-progress-circular>
        </v-btn>
        <v-btn
          color="primary"
          class="mb-2 mx-2"
          :disabled="disableControls"
          @click.prevent="showImportPartEvent()"
        >{{ $filters.capitalize($t(`part_view.import_sp`)) }}
        </v-btn>
        <v-btn
          color="primary"
          class="mx-2"
          :disabled="disableControls"
          @click.prevent="createFullPart(undefined)"
        >{{ $filters.capitalize($t(`part_view.create_full`)) }}
        </v-btn>
      </v-list>
    </v-menu>

    <v-toolbar-items>
      <MenuStock
        v-bind:stockage_id="stockage_id"
        v-bind:producer_type_id="producer_type_id"
        v-bind:loading="loadingStock"
        @chooseStock="menuChoose"
      ></MenuStock>
    </v-toolbar-items>
    <v-spacer></v-spacer>

    <v-toolbar-title style="font-weight: bolder" class="center pa-2 pb-3">{{
        $filters.capitalize($t(pageTitle, 10))
      }}
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <v-toolbar-items class="hidden-sm-and-down pa-2 pb-3">
      <v-btn
        v-if="isFromStaff"
        class="mr-3"
        color="primary"
        elevation="3"
        variant="outlined"
        :disabled="disableControls"
        @click.prevent="exportCsv()"
      >{{ $filters.capitalize($t(`part_view.export`)) }}
        <v-progress-circular
          class="ml-3"
          v-show="loadingExport"
          :indeterminate="true"
        ></v-progress-circular>
      </v-btn>

      <v-btn
        class="mr-3"
        color="primary"
        elevation="3"
        variant="outlined"
        :disabled="disableControls"
        @click.prevent="showImportPartEvent()"
      >{{ $filters.capitalize($t(`part_view.import_sp`)) }}
      </v-btn>

      <v-btn
        class="mr-3"
        color="primary"
        elevation="3"
        variant="outlined"
        :disabled="disableControls"
        @click.prevent="createFullPart(undefined)"
      >{{ $filters.capitalize($t(`part_view.create_full`)) }}
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>

  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    :itemsPerPage="options.itemsPerPage"
    :items-per-page-text='$t("rows_per_page")'
    :items-per-page-options='itemsPerPageOptions'
    :row-props="bgClass"
    :loading="loading"
    item-value="pk"
    fixed-header
    show-select
    :search="searchText"
  >
    <!--      no footer :: pagination info-->
    <template #top>
      <v-row justify="space-between" class="px-2 ma-0">
        <v-btn
          class="ma-2 pa-2"
          color="primary"
          :disabled="selected.length == 0"
          @click="changeCategoryBtn()"
        >
          <v-icon class="mr-1">mdi-cat
          </v-icon><!-- mdi-group -->
          {{ $filters.capitalize($t("actions.choose_category")) }}
        </v-btn>
    <v-text-field
          class="ma-2"
          v-model="searchText"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          single-line
          variant="outlined"
          hide-details
        ></v-text-field>

        <v-btn class="ma-2 pa-0" color="primary" @click="openHelpBtn()">
          <v-icon large color="white">mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-row>
    </template>
    <template v-slot:item.category__name="{ item }">
        <span
          v-if="
            $store.getters.language === 'en' && item.category__name_en !== ''
          "
        >
          {{ item.category__name_en }}
        </span>
      <span v-else>
          {{ item.category__name }}
        </span>
    </template>
    <template v-slot:item.unity="{ item }">
      {{ $filters.capitalize($filters.unityFilter(item.unity)) }}
    </template>

    <template v-slot:item.is_locally_bought="{ item }">
      <v-tooltip location="top" v-if="item.is_locally_bought"
                  :text="$filters.capitalize($t('stock_parts.is_locally_bought_.true') )">
        <template v-slot:activator="{props}">
          <v-icon v-bind="props">mdi-account-eye-outline</v-icon>
        </template>
      </v-tooltip>
      <v-tooltip location="top" v-else :text="$filters.capitalize($t('stock_parts.is_locally_bought_.false') )">
        <template v-slot:activator="{props}">
          <v-icon v-bind="props">mdi-account-lock-outline</v-icon>
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.image_url="{ item }">
      <v-img
        v-if="item.image"
        :src="item.image_url"
        max-height="32px"
        max-width="32px"
        contain
        @click="zoom(item.image_url)"
      ></v-img>
    </template>

    <template v-slot:item.serials="{ item }">
      <v-tooltip location="top" :text="$filters.capitalize($t('actions.show_serials') )">
        <template v-slot:activator="{props}">
          <v-icon
            v-if="item.serials.length > 0"
            v-bind="props"
            class="mr-2"
            @click="showSerials(item.serials)"
          >mdi-barcode-off
          </v-icon>
        </template>
      </v-tooltip>
    </template>
    <template v-slot:item.place_names="{item}">{{ item.place_names.join(' , ') }}</template>

    <template v-slot:item.actions="{ item }">
      <v-tooltip location="top" :text="$filters.capitalize($t('actions.edit') )">
        <template v-slot:activator="{props}">
          <v-icon
            size="1em"
            v-bind="props"
            class="mr-2"
            @click="createFullPart(item)"
            @click.right="createFullPart_newTab(item)"
          >mdi-pencil
          </v-icon>
        </template>
      </v-tooltip>
      <v-tooltip location="top" :text="$filters.capitalize($t('add') )">
        <template v-slot:activator="{props}">
          <v-icon
            size="1em"
            v-bind="props"
            class="mr-2"
            @click="addItemBtn(item)"
          >mdi-plus-thick
          </v-icon>
        </template>
      </v-tooltip>

      <v-tooltip location="top" :text="$filters.capitalize($t('actions.delete_item') )">
        <template #activator="{props}">
          <v-icon
            v-show="isFromStaff"
            size="1em"
            v-bind="props"
            class="mr-2"
            @click="deleteItem(item)"
          >mdi-delete-empty-outline
          </v-icon>
        </template>
      </v-tooltip>

      <v-tooltip location="top" :text="$filters.capitalize($t('actions.delete') )">
        <template v-slot:activator="{props}">
          <v-icon
            v-show="isFromStaff"
            size="1em"
            v-bind="props"
            @click="deleteLineBtn(item)"
          >mdi-delete
          </v-icon>
        </template>
      </v-tooltip>
    </template>
  </v-data-table>
</v-container>
</template>

<script>
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import { api } from "@/services/api";
import {
  alertPopup,
  extractFilename,
  isNone,
  slicyUpdate,
  unProxy
} from "@/functions.js";
import CategoryForm from "@/components/CategoryForm";
import ConsumableForm from "@/components/ConsumableForm";
import EditDialog from "@/components/EditDialog";
import MenuStock from "@/components/MenuStock";
import PartForm from "@/components/PartForm";
import PartQuantityForm from "@/components/PartQuantityForm";
import PartSerialForm from "@/components/PartSerialForm";
import { mapState } from "vuex";

export default {
  mixins: [filters, utils],

  components: {
    EditDialog: EditDialog,
    CategoryForm: CategoryForm,
    ConsumableForm: ConsumableForm,
    MenuStock: MenuStock,
    PartForm: PartForm,
    PartQuantityForm: PartQuantityForm,
    PartSerialForm: PartSerialForm
  },

  computed: mapState({
    module(state) {
      return this.getClassText(state, this.moduleName);
    },
    items() {
      return this.module.items;
    },
    category_id() {
      return this.$store.getters.dataEdit("stock_parts").category_id;
    },
    headers() {
      return [

         {
           title: this.$t("part_categories.label"),
          key: "category__name"
        },
        // to remove group column
  //      {
  //        title: "",
  //        key: "data-table-group",
  //        width: "0px",
  //        align: "center",
  //        sortable: false
  //      },
        {
          title: this.$t("stock_parts.name"),
          key: "name"
        },
        {
          title: this.$t("stock_parts.ref"),
          key: "ref"
        },
        {
          title: this.$t("consumables.stock"),
          key: "quantity"
        },
        {
          title: this.$t("stock_parts.min_quantity"),
          key: "min_quantity"
        },
        {
          title: this.$t("stock_parts.max_quantity"),
          key: "max_quantity"
        },
        {
          title: this.$t("part_view.serials"),
          key: "serials"
        },
        {
          title: this.$t("stock_parts.image"),
          key: "image_url"
        },
        {
          title: this.$t("stock_parts.is_locally_bought"),
          key: "is_locally_bought"
        },
        {
          title: this.$t("parts.place", 10),
          key: "place_names"
        },
        {
          title: this.$filters.capitalize(this.$t("unity.label", 1)),
          key: "unity",
          align: "center"
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false
        }
      ];
    },
    ...utils.isFromStaff
  }),

  created() {
    // Preload a stockage / producer type
    this.stockage_id = parseInt(this.$route.query.stockage_id) || undefined;
    this.producer_type_id = parseInt(this.$route.query.producer_type_id) || undefined;
  },
  mounted() {
    this.loadPage();
  },

  unmounted() {
    this.$store.dispatch("parts/cleanupItems");
    this.$store.dispatch("real_parts/cleanupItems");
  },

  watch: {
    showAddPart(val) {
      if (!val) {
        this.$store.getters.dataEdit("units").serial = "";
      }
    }
  },

  methods: {
    concatItems(placeList) {
      return placeList.join(' '); // Ceci va concaténer les éléments de la liste avec une virgule et un espace
    },

    loadPage() {
      this.disableControls =
        isNone(this.stockage_id) || isNone(this.producer_type_id);
      if (!isNone(this.stockage_id) && !Number.isNaN(this.stockage_id)) {
        this.loadingStock = true;
        let options = {
          size: -1,
          stockage: this.stockage_id,
          producer_type: this.producer_type_id,
          lang: this.$store.getters.language
        };
        this.$store.dispatch("real_parts/getView", options).then(() => {
          this.loadingStock = false;
        });
        // TODO: add condition so it only dispatches when stockage changes
        this.$store.dispatch("places/searchItems", {
          stockage_id: this.stockage_id,
          size: 300
        });
      }
    },

    menuChoose(value) {
      this.stockage_id = value.stockage_id;
      this.producer_type_id = value.producer_type_id;
      this.selected = [];
      // this.$store.dispatch("places/searchItems", {producer_type_id:this.producer_type_id, stockage_id:this.stockage_id});
      this.loadPage();
    },

    exportCsv() {
      this.loadingExport = true;
      const payload = {
        stockage_id: this.stockage_id,
        producer_type_id: this.producer_type_id
      };
      api.defaults.timeout = 30000;
      api
        .get("parts/export/", { params: payload })
        .then((response) => {
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = extractFilename(response.headers, "Export.csv");
          link.click();
          URL.revokeObjectURL(link.href);
          this.loadingExport = false;
        })
        .catch(() => {
          console.error;
          this.loadingExport = false;
        });
    },
    showImportPartEvent() {
      this.showImportPart = true;
      this.$store.dispatch("parts/cleanupEdit");
    },

    createFullPart(item) {
      let query = {
        stockage_id: this.stockage_id,
        producer_type_id: this.producer_type_id
      };
      if (!isNone(item)) {
        query.stock_part_id = item.pk;
      }
      this.$router.push({ name: "part_edition", query: query });
    },
    createFullPart_newTab(item) {
      var params = {};
      var query = {
        stockage_id: this.stockage_id,
        producer_type_id: this.producer_type_id
      };
      if (!isNone(item)) {
        params = { part: String(item.part_id).toString() };
        query.stock_part_id = item.pk;
      }
      const routeData = this.$router.resolve({
        name: "part_edition",
        params: params,
        query: query
      });
      window.open(routeData.href, "_blank");
    },

    deleteItem(item) {
      this.isUnit = item.serials.length > 0 || item.unity == 'un';
      if (this.isUnit) {
        this.$store.dispatch("units/updateEdit", item);
      } else {
        this.$store.dispatch("consumables/updateEdit", item);
        this.$store.state.consumables.edit.quantity = 0;
      }
      this.editedLine = item;
      this.editedStockPartId = item.pk;
      this.showDeleteItem = true;
    },

    saveDeleteItem() {
      if (this.isUnit) {
        let new_aff= {
                kind: 'ts',
                trash_state_id:1,
                unit_id: this.$store.state.units.search[0].pk,
                ...this.$store.state.units.struct,
            }
        this.$store
          .dispatch('affectations/affect', new_aff)
          .then(() => {
            this.itemsUpdate(
              this.editedLine.pk,
              "serial",
              this.$store.state.units.search[0].serial
            );
            this.$store.dispatch(
              "snackbar/showSuccess",
              this.$t("units.removed_success")
            );
            this.$store.dispatch("units/cleanupEdit");
          });
      } else {
        const rm_quantity = -1 * this.$store.state.consumables.edit.quantity;
        const item = {
          place_id: this.$store.state.consumables.edit.place_ids[0],
          stock_part_id: this.$store.state.consumables.edit.pk,
          quantity: rm_quantity
        };
        this.$store.dispatch(`consumables/addItem`, item).then(() => {
          this.itemsUpdate(this.editedLine.pk, "quantity", rm_quantity);
          this.$store.dispatch(
            "snackbar/showSuccess",
            this.$t("consumables.removed_success")
          );
          this.$store.dispatch("consumables/cleanupEdit");
        });
      }
      this.showDeleteItem = false;
    },

    deleteLineBtn(item) {
      alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
        if (result) {
          if (isNone(item.part_id)) {
            this.$store
              .dispatch("real_parts/setInvisible", {
                pk: item.stockpartlimit,
                visible: false
              })
              .then(() => {
                this.$store.dispatch(
                  "snackbar/showSuccess",
                  this.$t("snack.delete_success")
                );
                this.loadPage();
              });
          } else {
            this.$store
              .dispatch(`${this.moduleName}/deleteFullPart`, item.part_id)
              .then(() => {
                this.$store.dispatch(
                  "snackbar/showSuccess",
                  this.$t("snack.delete_success")
                );
                this.loadPage();
              });
          }
        }
      });
    },

    showSerials(serials) {
      this.serials = serials;
      this.showSerialsModal = true;
    },

    zoom(url) {
      // Zoom on this image
      this.imageInLargeSrc = url;
      this.imageInLarge = true;
    },

    itemsUpdate(pk, mode, more) {
      // pk: line identification
      // mode: the key to update
      // more: the value to update
      const index = this.items.findIndex((obj) => obj.pk === pk);
      if (index !== -1) {
        let line = this.items[index];
        if (mode === "quantity") {
          line.quantity = line.quantity + more;
        } else if (mode === "serial") {
          const jndex = line.serials.findIndex((obj) => obj === more);
          if (jndex !== -1) {
            line.serials.splice(jndex, 1);
            line.quantity = line.quantity - 1;
          } else {
            line.serials.push(more);
          }
        }
        this.$store.dispatch("parts/setPaginationSize", this.items.length);
      }
    },

    _savePart(edit, line_pk, unity) {
      // Generic method to save a part
      // * edit: which to save
      // * line_pk: which line in the item array
      // * unity: which stock part unity type
      let is_missing = [];
      if (isNone(edit.stock_part_id)) {
        is_missing.push(this.$t("part_view.missing.stockpart"));
      }
      if (isNone(edit.place_id)) {
        is_missing.push(this.$t("part_view.missing.place"));
      }
      if (this.$store.state.unitItems_.includes(unity)) {
        if (isNone(edit.serial)) {
          is_missing.push(this.$t("part_view.missing.serial"));
        }
      }
      if (is_missing.length > 0) {
        this.$store.dispatch(
          "snackbar/showWarning",
          this.$t("part_view.missing.sentence", {
            slice: is_missing.join(", ")
          })
        );
        return true;
      }

      if (this.$store.state.unitItems_.includes(unity)) {
        if (isNone(edit.serial)) {
          this.$store.dispatch(
            "snackbar/showWarning",
            this.$t("parts.missing.serial")
          );
          return true;
        }
        this.$store.dispatch(`units/addItem`, edit).then(() => {
          this.loadPage();

          this.$store.dispatch(
            "snackbar/showSuccess",
            this.$t("units.added_success")
          );
        });
      } else {
        if (!isNone(line_pk) && (isNone(edit.quantity) || edit.quantity == 0)) {
          // empty movement !
          return false;
        }
        if (isNone(line_pk)) {
          edit.quantity = 0;
        }
        this.$store.dispatch(`consumables/addItem`, edit).then(() => {
          this.loadPage();

          this.$store.dispatch(
            "snackbar/showSuccess",
            this.$t("consumables.added_success")
          );
        });
      }
      return false;
    },

    saveImportStockPart() {
      const edit = this.$store.state.parts.edit;
      this.showImportPart = this._savePart(
        edit,
        undefined,
        this.$store.state.stock_parts.edit.unity
      );
    },
    addItemBtn(item){
    if (item.unity === 'un' ) this.addPartItemBtn(item)
    else this.addConsumableItemBtn(item)
    },
    addConsumableItemBtn(item) {
      this.editedLine = item;
      let stock_part_id = item.pk;
      // if (stock_part_id === undefined) { stock_part_id = item.pk }
      this.$store.dispatch(`consumables/setEditStockPart`, stock_part_id);
      this.place_id = item.place_ids[0];
      this.showAddConsumable = true;
    },

    // TODO: change this to itemsUpdate?
    saveAddConsumableItem() {
      const edit = this.$store.state.consumables.edit;
      this.showAddConsumable = this._savePart(
        edit,
        this.editedLine.pk,
        this.editedLine.unity
      );
    },

    addPartItemBtn(item) {
      this.editedLine = item;
      this.stock_part_id = item.pk;
      this.place_id = item.place_ids[0];
      if (this.place_id !== undefined) {
        this.$store.dispatch(`units/setEditPlace`, this.place_id);
      }
      let stock_part_id = item.pk;
      // The button is used on stock_part_context
      if (stock_part_id !== undefined) {
        this.$store.dispatch(`units/setEditStockPart`, stock_part_id);
      }
      this.showAddPart = true;
    },

    saveAddPartItem() {
      const edit = this.$store.state.parts.edit;
      this.showAddPart = this._savePart(
        edit,
        this.editedLine.pk,
        this.editedLine.unity
      );
    },

    changeCategoryBtn() {
      this.$store.dispatch(`stock_parts/cleanupEdit`).then(() => {
        this.editCategory = true;
      });
    },

    openHelpBtn() {
      this.helpDialog = true;
    },

    saveCategoryEvent() {
      let done = 0;
      for (let key in this.selected) {
        let stock_part_id = this.selected[key];
        this.$store
          .dispatch(`stock_parts/patch`, {
            id_: stock_part_id,
            payload: { category_id: this.category_id }
          })
          .then(() => {
            done = done + 1;
            this.endCategoryMove_question_mark(done);
          })
          .catch(() => {
            done = done + 1;
            this.endCategoryMove_question_mark(done);
          });
      }
      this.$refs.editDialogCategory.closeDialog(); // do reinit of this.category_id
    },

    endCategoryMove_question_mark(done) {
      if (done >= this.selected.length) {
        this.loadPage();
        this.selected = [];
      }
    },

    cleanupCategoryDialog() {
      this.editCategory = false;
      this.$store.dispatch(
        `stock_parts/updateEdit`,
        this.$store.state["stock_parts"].struct
      );
    },

    bgClass(item) {
      let it_ = item.item;
      var classes = [];
      if(!isNone(it_.quantity && it_.min_quantity)){
        if(it_.quantity < it_.min_quantity){
          classes.push("low_quantity")
        } 
      }
      return {class: classes.join(" ")}
    },
  },

  data() {
    return {
      pageTitle: "part_view.label",
      moduleName: "real_parts",
      //headers: [],  // Must be computed
      searchText: "",
      stockage_id: NaN,
      place_id: NaN,
      stock_part_id: NaN,
      producer_type_id: NaN,
      options: {
        itemsPerPage: 10
      },
      itemsPerPageOptions: [10,20,50,-1],
      loadingExport: false,
      loadingStock: false,
      disableControls: true,
      imageInLarge: false,
      imageInLargeSrc: null,
      showSerialsModal: false,
      serials: [],
      showImportPart: false,
      showAddConsumable: false,
      showAddPart: false,
      showDeleteItem: false,
      editedLine: undefined,
      selected: [],
      editCategory: false,
      editedStockPartId: undefined,
      isUnit: false,
      helpDialog: false,
      limits: undefined
    };
  }
};
</script>

<style>
table td .v-image {
  cursor: pointer;
}

div.v-dialog__content .v-dialog {
  background-color: white;
}

/* Vuetify ugly sticky header hack */
:deep(.v-data-table__wrapper) {
  height: calc(100vh - 170px) !important;
}

/*TODO: load these variables.*/
:deep(tr.under_threshold),
:deep(tr.under_threshold.v-data-table__selected) {
  background-color: var(--v-error-lighten3);
}

:deep(tr.under_threshold:hover) {
  background-color: var(--v-error-lighten2) !important;
}

.imageInLargeDialog img {
  object-fit: contain;
}

td .v-image {
  cursor: pointer;
}

:deep(.unit_header) {
  text-transform: capitalize;
}

tr.low_quantity {
  background-color: #f2c2bd !important;
}

/* #toolbar {
  z-index: 100;
} */
</style>

<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          v-model="localLine.stock_part.name"
          readonly
          :label="$filters.capitalize($t('stock_parts.name'))"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="2">
        <v-text-field
          :model-value="localLine.quantity"
          type="number"
          :disabled="!creation"
          oninput="if(this.value < 0) this.value = 0;"
          :label="$filters.capitalize($t('purchase_lines.quantity'))"
        ></v-text-field>
      </v-col>
      <v-col cols="8" sm="4" md="2">
        <v-text-field
          :model-value="localLine.price"
          type="number"
          :clearable="true"
          oninput="if(this.value < 0) this.value = 0;"
          :label="$filters.capitalize($t('purchase_lines.price') )"
        ></v-text-field>
      </v-col>
      <v-col cols="4" sm="4" md="2">
        <SelectCurrency
          v-bind:currency="localLine.price_currency"
          @change="chooseCurrency"
        ></SelectCurrency>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        stock: {{ localLine.current_stock }} | min:
        {{ localLine.min_quantity }} | max: {{ localLine.max_quantity }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { isNone } from "@/functions.js";
import SelectCurrency from "@/components/SelectCurrency.vue";

export default {
  props: {
    line: { type: Object, required: true },
    creation: { type: Boolean, required: false, default: true },
  },
  components: { SelectCurrency: SelectCurrency },

  watch: {
    line(newVal) {
      this.localLine = JSON.parse(JSON.stringify(newVal));
    }
  },

  methods: {
    chooseCurrency(value) {
      this.editedItem.price_currency = value;
    }
  },

  computed: {
    selected: function() {
      if (this.creation) {
        return this.$store.state[`${this.moduleName}`].selected;
      }
      return this.$store.state[`purchases`].edit.lines;
    },
    editedItem_quantity: {
      get: function() {
        if (isNone(this.editedItem)) {
          return undefined;
        }
        return this.editedItem.quantity;
      },
      set: function(value) {
        if (this.creation) {
          this.$store.dispatch("purchase_lines/updateSelectedQuantity", {
            index: this.ordinal,
            quantity: value
          });
        }
      }
    },
    stockPart: function() {
      if (isNone(this.editedItem)) {
        return undefined;
      }
      return this.editedItem.stock_part;
    },
    stockPartName: function() {
      if (isNone(this.stockPart)) {
        return "";
      }

      if (this.$store.getters.language == "en") {
        if (!isNone(this.stockPart.ref)) {
          return `${this.stockPart.name_en} – ${this.stockPart.ref}`;
        }
        return `${this.stockPart.name_en}`;
      } else {
        if (!isNone(this.stockPart.ref)) {
          return `${this.stockPart.name} – ${this.stockPart.ref}`;
        }
        return `${this.stockPart.name}`;
      }
    }
  },

  data() {
    return {
      moduleName: "purchase_lines",
      localLine: JSON.parse(JSON.stringify(this.line)),
    };
  }
};
</script>

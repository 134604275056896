<template>
  <v-container ma-0 pa-0>
    <v-row>
      <v-col cols="6" class="">
        <DatePicker
          :label="label"
          :value="dateValue"
          :showIcon='false'
          @input="handleDateChanges"
        >
        </DatePicker>
      </v-col>
      <v-col cols="6" class="">
        <v-radio-group
          v-model="timeValue"
          :nudge-right="80"
          offset-y
          row
          @update:modelValue="handleTimeChanges"
        >
          <v-radio
            v-bind:label="$filters.capitalize($t('scheduler.datetime.morning', 1))"
            value="morning"
          ></v-radio>
          <v-radio
            v-bind:label="$filters.capitalize($t('scheduler.datetime.afternoon', 1))"
            value="afternoon"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import DatePicker from "../DatePicker.vue";
import { th } from "vuetify/locale";

export default {
  name: "DatePartPicker",

  props: {
    label: { type: String, required: true },
    value: { type: [String, Date], default: "" },
    isStart: { type: Boolean, default: true },
    moduleName: { type: String, default: 'datePart' },
  },

  components: {
    DatePicker
  },

  created(){
    const { date_, time_ } = this.extractDateAndTime(this.value);
    this.dateValue = date_;
    this.setTimeValue(this.value);
    this.combinedDateTime = this.value;
  },

  data() {
    return {
      timeValue: null,
      dateValue: null,
      combinedDateTime: null
    };
  },

  methods: {
    extractDateAndTime(dateTimeObj) {
      if (!(dateTimeObj instanceof Date)) {
        console.error("bad type date");
        return { date_: null, time_: null };
      }

      const year = dateTimeObj.getFullYear();
      const month = dateTimeObj.getMonth() + 1; // getMonth() is zero-based
      const day = dateTimeObj.getDate();

      const monthPadded = month < 10 ? `0${month}` : month.toString();
      const dayPadded = day < 10 ? `0${day}` : day.toString();

      let date_ = `${year}-${monthPadded}-${dayPadded}`;

      const hours = dateTimeObj.getHours();
      const minutes = dateTimeObj.getMinutes();
      const seconds = dateTimeObj.getSeconds();

      const hoursPadded = hours < 10 ? `0${hours}` : hours.toString();
      const minutesPadded = minutes < 10 ? `0${minutes}` : minutes.toString();
      const secondsPadded = seconds < 10 ? `0${seconds}` : seconds.toString();

      let time_ = `${hoursPadded}:${minutesPadded}:${secondsPadded}`;

      return { date_, time_ };
    },



    handleDateChanges(val) {
      this.dateValue = val;
      this.handleChange();
    },

    handleTimeChanges(val) {
      this.timeValue = val;
      this.handleChange();
    },

    handleChange() {
      if (!this.dateValue || !this.timeValue) {
        console.error("Date or Time values are not set");
        return;
      }

      this.setCombinedDateTime();
    },

    setCombinedDateTime() {
      if (!this.dateValue || !this.timeValue) {
        console.error("Date or Time values are not set");
        return;
      }

      const _time = this.readTimeString(this.timeValue);
      const _date = this.dateValue;
      const dateTimeString = `${_date}T${_time}`;

      this.combinedDateTime = new Date(dateTimeString);

      if (isNaN(this.combinedDateTime.getTime())) {
        console.error("Invalid combined date and time");
        return;
      }
      this.$emit('inputDateTimePart', this.combinedDateTime);
    },

    createDate(date, time) {
      const dateTimeString = `${date}T${time}`;
      return new Date(dateTimeString);
    },

    setTimeValue(value) {
      //check 14 timezone !
      this.timeValue = value.getHours() < 14 ? "morning" : "afternoon";
    },

    readTimeString(value) {
      var time = "";
      if (value === "morning") {
        if (this.isStart) {
          time = "00:00";
        } else {
          time = "12:00";
        }
      } else {
        if (this.isStart) {
          time = "12:00";
        } else {
          time = "23:59";
        }
      }
      return time;
    },
  },
};
</script>

<template>
<v-container>
  <div style="display: none">
    <EditDialog
      v-bind:model="editStockQuantity"
      v-bind:moduleName="'parts'"
      v-bind:btnName="''"
      v-bind:smallWidth="true"
      v-bind:formTitle="$t('edit_item')"
      @save="saveItem"
      @close="cleanupStockDialog"
      ref="editDialogStock"
    >
      <template v-slot:edit_content>
        <v-text-field
          v-model="editedItem.item.total"
          hide-details
          single-line
          type="number"
        />
      </template>
    </EditDialog>
  </div>

  <v-card class="pa-2 mt-3 mx-0">
    <v-card-title>{{$filters.capitalize( $t("stockages.label", 1) ) }}</v-card-title>
    <v-card elevation="0">
      <v-row class="pa-4 mx-3" justify="start" density= 'compact'>
        <v-col cols="6" md="4">
          <v-select
            v-model="edition.stockage_id"
            :items="$store.state.stockages.items"
            item-title="name"
            item-value="pk"
            :label="$filters.capitalize($t('stockages.label', 1) )"
            @update:modelValue="chooseStockageEvent"
          >
            <template v-slot:no-data>
              <v-list-item>
                  {{$filters.capitalize(
                    $t("search_for", { noun: $t("stockages.label", 1) })
                      )
                  }}
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="min_quantity"
            type="number"
            min="0"
            :label="$filters.capitalize($t('stock_parts.min_quantity') )"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="max_quantity"
            type="number"
            min="0"
            :label="$filters.capitalize($t('stock_parts.max_quantity') )"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- EMPLACEMENTS/AFFECTATIONS -->
    <!-- UNITS -->
      <v-row class="px-2" v-show="placesTotal != undefined" density= 'compact'>
        <v-col>
            <v-data-table
              class="ma-4"
              :headers="headers_units"
              :items="placesTotal"
              :loading="loadingPlaces"
              v-show="isUnit"
              :hide-default-footer="placesTotal.length < 10"
              density= 'compact'
            >
              <template #bottom></template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip location="top"  :text="$filters.capitalize( $t('actions.edit'))">
                  <template v-slot:activator="{ props }">
                    <v-icon small v-bind="props" @click="viewUnit(item)">
                      mdi-eye-outline
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </v-data-table>
            <!--CONSUMABLES -->
            <v-data-table
              class="ma-4"
              :headers="headers_cons"
              :items="placesTotal"
              :loading="loadingPlaces"
              v-show="!isUnit"
              :hide-default-footer="placesTotal.length < 10"
              density= 'compact'
            >
              <template #bottom></template>
              <template v-slot:item.total='data'>
                {{data.item.total}}
                <v-icon @click="openEditStockQuantity(data)">
                  mdi-pencil
                </v-icon>
              </template>
              <template v-slot:item.actions="{item}">
                <v-icon
                  v-show="isSquashable(item)"
                  small
                  @click="squashLineBtn(item)"
                  >mdi-delete</v-icon
                >
<!--                <v-icon
                  small
                  @click="editLineBtn(item)"
                  >mdi-pencil</v-icon
                >-->
              </template>
            </v-data-table>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

      <!-- ADD NEW PLACE  + QTY / SER -->
      <v-row class="px-2">
        <v-card-subtitle class='ml-3' density= 'compact'>
          {{$filters.capitalize($t("part_edition.add_place") )}}
        </v-card-subtitle>
      </v-row>
      <v-row class="px-2" justify="start" density= 'compact'>
        <v-col cols="5" md="4">
          <v-autocomplete
            class='pl-6'
            v-model="newPlacement.place"
            :items="placesDropDown"
            item-value="pk"
            item-title="name"
            :label="$filters.capitalize($t('places.label', 1) )"
            v-model:search="autocompleteSearchPlace"
            :loading="$store.state.places.isSearching"
            :filter="filterPlaces"
            :disabled="!edition.stockage_id"
            @update:modelValue="updateNewPlacement"
            append-icon="mdi-text-box-search"
            :clearable="true"
            variant="solo"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{$filters.capitalize(
                    $t("search_for", { noun: $t("places.label", 1) })
                      )
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{item, props:{onClick}}">
              <v-list-item :title="item.raw.shelf != null ?
              item.raw.stockage.name + ' - ' + item.raw.cabinet + '/'+ item.raw.shelf
              : item.raw.stockage.name + ' - ' + item.raw.cabinet"
              @click="onClick"
              />
            </template>
            <template v-slot:selection="{item}">
              <span v-if="item.raw.shelf != null">
                {{ item.raw.cabinet }} / {{ item.raw.shelf }}
              </span>
              <span v-else>
                {{ item.raw.cabinet }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip location="top"  :text="$filters.capitalize( $t('actions.edit'))">
                <template v-slot:activator="{ props }">                  <v-icon small v-bind="on" @click="viewUnit(item)"
                    >mdi-eye-outline</v-icon
                  >
                </template>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-show="!isUnit" cols="3" md="2">
          <v-text-field
            v-show="!isUnit"
            v-model.number="newPlacement.total"
            type="number"
            min="0"
            :label="$filters.capitalize($t('consumables.stock') )"
          ></v-text-field>
        </v-col>
        <v-col v-show="isUnit" cols="4" md="3">
          <v-text-field
            v-show="isUnit"
            v-model="newPlacement.serial"
            :label="$filters.capitalize($t('units.serial', 1) )"
          ></v-text-field>
        </v-col>
        <v-col cols="3" md="2">
          <v-btn
            :disabled="!newPlacementIsValid"
            variant="outlined"
            size="small"
            min-width='200px'
            @click="saveAddPlacement()"
            >{{$filters.capitalize( $t("part_edition.add_place") ) }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-toolbar text color="white" class=" mt-3" elevation="0">
      <v-spacer></v-spacer>
      <v-btn color="success" @click="saveItem()">
        {{$filters.capitalize( $t("save") ) }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>
  </v-card>
</v-container>
</template>

<script>
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";

import { isNone } from "@/functions";
import EditDialog from "@/components/EditDialog.vue";

export default {
  components: { EditDialog },
  mixins: [filters, utils],
  props: {
    edition: { type: Object, required: true },
    showSPS: { type: Boolean, required: false },
  },
  computed: {
    parts_edit: function () {
      return this.$store.state["parts"].edit;
    },
    ...utils.computed,
    newPlacementIsValid() {
      if (
        this.newPlacement.place != "" &&
        (this.newPlacement.total >= 0 || this.newPlacement.serial != "")
      ) {
        return true;
      }
      return false;
    },
    isUnit() {
      return this.$store.state.stock_parts.edit.unity == "un" ? true : false; //using builtin function slows page down
    },
  },

  methods: {

    saveStockEvent() {
      this.editStockQuantity = false;
      return this.$store.dispatch(`parts/editItem`, this.parts_edit).then(() => {
        this.$refs.editDialogStock.closeDialog();
      });
    },
    cleanupStockDialog() {
      this.editStockQuantity = false;
    },

    //HACK FIX VUETIFY https://github.com/logaretm/vee-validate/issues/4417
    convertPlacesStrToNum(placesTotalTmp) {
      if (Array.isArray(placesTotalTmp)) {
        placesTotalTmp.forEach(item => {
          if (item.total && !isNaN(item.total)) {
            item.total = parseInt(item.total, 10);
          }
        });
        return placesTotalTmp
      }
    },

    openEditStockQuantity(item) {
      if (item) {
        this.$store.dispatch(`parts/updateEdit`, item).then(() => {
          this.$emit("editedItem");
        });
      } else {
        this.$store.dispatch(
          `parts/updateEdit`,
          this.$store.state["parts"].struct
        );
      }
      this.editStockQuantity = true;
    },
    isSquashable(item) {
      if (this.placesTotal.length <= 1) return false;
      if (item.total > 0) return false;
      return true;
    },
    squashLineBtn(item) {
      this.$store
        .dispatch(`${this.moduleName}/squashPlace`, {
          place_id: item.place,
          stock_part_id: this.edition.stock_part_id,
        })
        .then((places) => {
          this.placesTotal = places;
          this.$store.dispatch(
            "snackbar/showSuccess",
            this.$t("snack.update_success"),
          );
        });
    },
    editLineBtn(item){
        this.editQuantity=true
    },
    viewUnit(item) {
      if (!this.placesTotal.every(a=>a.id)){
        this.$store.dispatch(
            "snackbar/showError",
            this.$t("units.save_first")
          );
        return false
      }

        let query = {
          part: String(item.id).toString(),
        };
        this.$router.push({ name: "unit", query: query });
    },
    saveItem() {
      if (this.newPlacementIsValid) {
        this.saveAddPlacement();
      } else if (this.placesTotal.length == 0 && !this.isUnit) {
        let imprecise = this.$store.state.places.search.find(
          (place) => place.cabinet === "imprecise",
        );
        this.resetNewPlacement();
        (this.newPlacement.name = imprecise.name),
          (this.newPlacement.place = imprecise.pk),
          (this.newPlacement.total = 0),
          this.saveAddPlacement();
      }

      let is_missing = [];
      if (isNone(this.placesTotal) || this.placesTotal.length < 1) {
        is_missing.push(this.$t("part_edition.missing.place"));
      }
      if (isNone(this.edition.stockage_id)) {
        is_missing.push(this.$t("part_edition.missing.stockage"));
      }
      if (is_missing.length > 0) {
        this.$store.dispatch(
          "snackbar/showWarning",
          this.$t("part_edition.missing.sentence", {
            slice: is_missing.join(", "),
          }),
        );
        return false;
      }
      let full_part = {
        stock_part_id: this.edition.stock_part_id,
        places: this.placesTotal,
        min_quantity: this.min_quantity,
        max_quantity: this.max_quantity,
      };
      full_part.places = this.convertPlacesStrToNum(full_part.places);
      this.$store
        .dispatch(`${this.moduleName}/saveFullPlace`, full_part)
        .then((n_p) => {
          this.placesTotal = n_p;
          this.$store.dispatch(
            "snackbar/showSuccess",
            this.$t("snack.update_success"),
          );
        
        });
      this.editStockQuantity = false;
      // this.$emit('saved');
    },
    updateNewPlacement() {
      // workaround for form returning object #NotProud
      if (isNone(this.newPlacement.place)) {
        this.resetNewPlacement();
        return;
      }
      this.newPlacement.name = this.newPlacement.place.name;
      this.newPlacement.place = this.newPlacement.place.pk;
    },
    saveAddPlacement() {
      if (this.placesTotal) {
        this.placesTotal = [...this.placesTotal, this.newPlacement];
      } else {
        this.placesTotal = [this.newPlacement];
      }
      this.resetNewPlacement();
      this.saveItem();
      // TODO: refacto saveItem to call only second part and avoid possible loop
    },
    chooseStockageEvent(value) {
      this.$emit("chooseStockage", value);
      this.$store.dispatch("places/searchItems", {
        stockage_id: value,
      });

      let options = {
        stock_part_id: this.edition.stock_part_id,
        stockage_id: value,
      };
      this.getPlaceTotals(options);
      this.getLimits(options);
    },
    resetNewPlacement() {
      this.newPlacement = { name: "", place: "", total: 0, serial: "" };
    },
    getPlaceTotals(options) {
      this.loadingPlaces = true;
      this.$store.dispatch("parts/getPlaceTotals", options).then((places) => {
this.placesTotal = places;
        this.loadingPlaces = false;
      });
    },
    getLimits(options) {
      this.$store
        .dispatch("stock_part_limits/getItems", options)
        .then((items) => {
          if (items.length > 0) {
            this.min_quantity = items[0].min_quantity;
            this.max_quantity = items[0].max_quantity;
          } else {
            this.min_quantity = 0;
            this.max_quantity = undefined;
          }
        });
    },
    updatePlacesDropDown() {
      this.placesDropDown = this.$store.state.places.search;
      if (this.isUnit) return;
      for (let p of this.placesTotal) {
        this.placesDropDown = this.placesDropDown.filter(function (obj) {
          return obj.pk != p.place;
        });
      }
    },
  },
  mounted() {
    let q_stockage_id = parseInt(this.$route.query.stockage_id) || undefined;
    let q_stock_part_id = parseInt(this.$route.query.stock_part_id) || undefined;

    if (isNone(q_stockage_id)) return;
    if (!isNone(q_stock_part_id)) {
      let options = {
        stock_part_id: q_stock_part_id,
        stockage_id: q_stockage_id,
      };
      this.getPlaceTotals(options);
      this.getLimits(options);
    }
  },
  watch: {
    autocompleteSearchPlace(val) {
      if (!this.$store.getters.canSearch("places")) return;
      var payload = { value: val };
      if (this.edition.stockage_id !== undefined) {
        payload["stockage_id"] = this.edition.stockage_id;
      }
      this.$store.dispatch("places/searchItems", payload).then(() => {
        this.updatePlacesDropDown();
      });
    },
    showSPS() {
      // clear form when stock_part changes
      this.placesTotal = [];
      this.min_quantity = undefined;
      this.max_quantity = undefined;
    },
    placesTotal() {
      this.updatePlacesDropDown();
    },
  },
  data() {
    return {
      editStockQuantity: false,
      moduleName: "parts",
      placesDropDown: [],
      placesTotal: [],
      loadingPlaces: false,
      newPlacement: { name: "", place: "", total: 0, serial: "" },
      min_quantity: 0,
      max_quantity: undefined,
      canSave: false,
      autocompleteSearchPlace: null,
      // separate headers to allow separate primary keys
      headers_units: [
        {
          title: this.$t("parts.place", 1),
          key: "name",
          sortable: false,
        },
        {
          title: this.$t("units.serial", 1),
          key: "serial",
          sortable: false,
        },
        {
          title: "",
          key: "actions",
          sortable: false,
        },
      ],
      headers_cons: [
        {
          title: this.$t("parts.place", 10),
          key: "name",
          sortable: false,
        },
        {
          title: this.$t("consumables.stock"),
          key: "total",
          sortable: false,
        },
        {
          title: "",
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>
